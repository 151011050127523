import * as XLSX from 'xlsx';

export const DownloadExcelTemplate = () => {
    // Data for the main sheet
    const data = [
        ["Nombre y Apellido", "WhatsApp", "Distrito", "Cobrar", "Fecha", "Notas", "Direccion"],
        ["John Doe", "912345678", "Miraflores", "0", "2024-01-01", "Ejemplo de nota", "Av. Ejemplo 123"]
    ];

    // Instructions and district names
    const instructions = [
        ["Instrucciones:"],
        ["1. Nombre, Dirección, WhatsApp, Distrito, y Fecha son obligatorios."],
        ["2. WhatsApp debe tener solo 9 dígitos, sin código de país."],
        ["3. Use solo los nombres de distrito listados a continuación:"],
        ["4. Copie o escriba el nombre del distrito tal como aparece en la lista."],
        ["5. El formato correcto de fecha es MM-DD-AAAA. Ejemplo: 09/27/2024."]
    ];

    const districts = [
        "Ate", "Barranco", "Breña", "Carabayllo", "Chaclacayo", "Chorrillos",
        "Cieneguilla", "Comas", "El Agustino", "Independencia", "Jesús María",
        "La Molina", "La Victoria", "Lince", "Los Olivos", "Lurigancho", "Lurin",
        "Magdalena del Mar", "Miraflores", "Pachacamac", "Pueblo Libre", "Puente Piedra",
        "Rimac", "San Borja", "San Isidro", "San Juan de Lurigancho", "San Juan de Miraflores",
        "San Luis", "San Martin de Porres", "San Miguel", "Santa Anita", "Santiago de Surco",
        "Surquillo", "Villa El Salvador", "Villa Maria del Triunfo", "Cercado de Lima",
        "Callao", "Bellavista", "Carmen de La Legua Reynoso", "La Perla", "La Punta", "Ventanilla"
    ];

    const districtSheetData = [
        ["Lista de Distritos (No modificar):"],
        ...districts.map(district => [district])
    ];

    // Convert data to sheet
    const wsData = XLSX.utils.aoa_to_sheet(data);
    const wsInstructions = XLSX.utils.aoa_to_sheet([...instructions, [], ...districtSheetData]);

    // Style instructions with different colors
    const instructionStyle = { font: { bold: true, color: { rgb: "FF0000" } }, fill: { fgColor: { rgb: "FFFF00" } } };
    const districtStyle = { font: { bold: true, color: { rgb: "0000FF" } } };

    instructions.forEach((row, rowIndex) => {
        row.forEach((_, colIndex) => {
            const cell = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex });
            if (!wsInstructions[cell]) wsInstructions[cell] = {};
            wsInstructions[cell].s = instructionStyle;
        });
    });

    districtSheetData.forEach((row, rowIndex) => {
        row.forEach((_, colIndex) => {
            const cell = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + instructions.length + 2 });
            if (!wsInstructions[cell]) wsInstructions[cell] = {};
            wsInstructions[cell].s = districtStyle;
        });
    });

    // Create workbook and append sheets
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsData, "Plantilla");
    XLSX.utils.book_append_sheet(wb, wsInstructions, "Instrucciones");

    // Write file
    XLSX.writeFile(wb, "Plantilla_de_Entrega.xlsx");
};
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, InputAdornment } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DistrictSelect from './DistrictSelect';
import 'dayjs/locale/es';
import { esES } from '@mui/x-date-pickers/locales';

const Entrega = ({ onBackClick, onEntregaSuccess }) => {
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [additionalNotes, setAdditionalNotes] = useState('');
    const [recipientName, setRecipientName] = useState('');
    const [address, setAddress] = useState('');
    const [recipientPhone, setRecipientPhone] = useState('');
    const [distric, setDistrict] = useState('');
    const [charge, setCharge] = useState('0');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const ConfirmModal = ({ message, onConfirm, onCancel }) => (
        <div className="modal-overlay-entrega">
            <div className="modal-content">
                <div className="modal-message">{message}</div>
                <div className="modal-actions">
                    <button className="modal-ok-button" onClick={onConfirm}>OK</button>
                    <button className="modal-cancel-button" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    );

    const handleConfirmClick = () => {
        if (!recipientName || !recipientPhone || !distric || !deliveryDate) {
            setErrorMessage('Todos los campos obligatorios deben completarse.');
            return;
        }

        setErrorMessage('');
        setIsModalOpen(true);
    };

    const handleConfirm = async () => {
        setIsModalOpen(false);
        const token = localStorage.getItem('token');

        if (!token) {
            console.error("No token available.");
            return;
        }

        try {

            const formattedDeliveryDate = deliveryDate ? deliveryDate.format('YYYY-MM-DD') : null;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/entrega`, {
                user_id: localStorage.getItem('user_id'),
                delivery_date: formattedDeliveryDate,
                recipient_name: recipientName,
                recipient_phone: recipientPhone,
                distric: distric,
                charge: charge,
                additional_notes: additionalNotes,
                address: address,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log(response.data.message);
            onEntregaSuccess();
        } catch (error) {
            if (error.response) {
                console.error('Error creating entrega:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error', error.message);
            }
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
            <div className="entrega-container">
                <ArrowBackIcon onClick={onBackClick} style={{ color: 'black' }}/>
                
                <h1 style={{ color: 'black' }}>Tu Entrega</h1>
                <div className="form-row">
                    <TextField
                        className="form-field"
                        label="Nombre y Apellido"
                        value={recipientName}
                        onChange={(e) => setRecipientName(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        className="form-field"
                        label="WhatsApp"
                        value={recipientPhone}
                        onChange={(e) => {
                            const input = e.target.value;
                            const phoneNumber = input.slice(-9);
                            setRecipientPhone(phoneNumber);
                        }}
                        margin="normal"
                    />
                </div>
                <div className="form-row">
                    <DistrictSelect distric={distric} setDistrict={setDistrict} />
                    <TextField
                        className="form-field"
                        label="Cobrar"
                        value={charge}
                        onChange={(e) => setCharge(e.target.value)}
                        margin="normal"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">S/.</InputAdornment>,
                        }}
                        type="number"
                    />
                </div>
                <div className="form-row">
                    <DatePicker
                        label="Fecha de entrega"
                        inputFormat="DD/MM/YYYY"
                        value={deliveryDate}
                        onChange={(newValue) => setDeliveryDate(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <TextField
                        style={{ width: '70%' }}
                        label="Notas adicionales"
                        multiline
                        rows={1}
                        value={additionalNotes}
                        onChange={(e) => setAdditionalNotes(e.target.value)}
                    />
                </div>
                <TextField
                    label="Dirección"
                    multiline
                    rows={1}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    margin="normal"
                />
                <p style={{ color: 'black' }}>Los paquetes se entregarán entre las 14:00 y las 20:00 (hora local en Lima).</p>
                <div>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <Button variant="contained" onClick={handleConfirmClick} style={{ backgroundColor: '#000', color: '#fff', width: '100%' }}>
                        Confirmar
                    </Button>
                    {isModalOpen && (
                        <ConfirmModal
                            message="¿Está seguro de que la información ingresada es correcta?"
                            onConfirm={handleConfirm}
                            onCancel={handleCancel}
                        />
                    )}
                </div>
            </div>
        </LocalizationProvider>
    );
}

export default Entrega;

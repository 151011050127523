import React, { useState } from 'react';
import { Button, TextField, Grid, Container, Avatar, ListItem, ListItemAvatar } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

function EditOrder({ order, onBackClick, onSubmit }) {
    const [orderNotes, setOrderNotes] = useState(order.order_notes);
    const [address, setAddress] = useState(order.address);
    const [orderDate, setOrderDate] = useState(new Date(order.recojo_date));

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit({
            ...order,
            order_notes: orderNotes,
            address: address,
            recojo_date: orderDate.toISOString(),
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    
                    
                }}
                >
            <ListItem>
                <ListItemAvatar>
                    <Avatar onClick={onBackClick}>
                        <ArrowBackIcon />
                    </Avatar>
                </ListItemAvatar>
            </ListItem>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            label="Order Notes"
                            value={orderNotes}
                            onChange={(e) => setOrderNotes(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            label="Order Date"
                            value={orderDate}
                            onChange={setOrderDate}
                            renderInput={(props) => <TextField {...props} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" fullWidth variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
        </LocalizationProvider>
    );
}

export default EditOrder;
import React from "react";

function Input(props) {
    return (
      <input
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        style={props.style}
      />
    );
  }

export default Input;
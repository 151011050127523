import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import { ButtonBase, Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent, MenuItem, CircularProgress  } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsIcon from '@mui/icons-material/Directions';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SettingsIcon from '@mui/icons-material/Settings';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CallIcon from '@mui/icons-material/Call';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const formatArrivalTime = (arrivalTime) => {
  const date = new Date(arrivalTime);

  // Add 2 hours to the time
  date.setHours(date.getHours() - 5);

  // Format the time to HH:MM
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

function DeliveryDriver({ delivery, onBackClick }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [currentLocationMarker, setCurrentLocationMarker] = useState(null);
  const [driver, setDriver] = useState(null);
  const [driverMarker, setDriverMarker] = useState([]);
  const [deliveryMarker, setDeliveryMarker] = useState(null);
  const [note, setNote] = useState('');
  const [images, setImages] = useState([]);
  const [status, setStatus] = useState(delivery.status);
  const [charged, setCharged] = useState(delivery.charge);
  const [transfer, setTransfer] = useState(delivery.transfer);
  const [openChargedDialog, setOpenChargedDialog] = useState(false);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formattedArrivalTime, setFormattedArrivalTime] = useState('');
  const [openImageDialog, setOpenImageDialog] = useState(false);

  const statusOptions = ['entregado', 'cancelado', 'pospuesto'];

    const handleNoteClick = () => {
        setOpenNoteDialog(true);
    };

    const handleStatusClick = () => {
        setOpenStatusDialog(true);
    };

    const handleNoteSubmit = () => {
        setOpenNoteDialog(false);
    };

    const handleStatusSubmit = () => {
        setOpenStatusDialog(false);
    };

    const handleOpenChargedDialog = () => setOpenChargedDialog(true);
    const handleCloseChargedDialog = () => setOpenChargedDialog(false);

    const handleChargedSubmit = () => {
      if (transfer && !charged) {
        alert("Por favor ingrese el monto a cobrar si es una transferencia.");
        return;
      }
      
      setOpenChargedDialog(false);
      // Additional logic if needed when saving the charged and transfer data
    };

    useEffect(() => {
      if (delivery.arrival_time) {
          const adjustedTime = formatArrivalTime(delivery.arrival_time);
          setFormattedArrivalTime(adjustedTime);
      }
  }, [delivery.arrival_time]);

  function compressImage(file, quality = 0.6, maxWidth = 1000, maxHeight = 1000) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                // Maintain aspect ratio
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas to a Blob
                canvas.toBlob(
                    (blob) => {
                        if (blob) {
                            resolve(blob);
                        } else {
                            reject(new Error('Compression failed'));
                        }
                    },
                    'image/jpeg', // Output format
                    quality // Quality of the output image (0.0 - 1.0)
                );
            };

            img.onerror = (error) => reject(error);
        };

        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
}

  const drawRoute = (coordinates) => {
      if (mapRef.current.getSource('route')) {
          mapRef.current.getSource('route').setData({
              type: 'Feature',
              properties: {},
              geometry: {
                  type: 'LineString',
                  coordinates: coordinates
              }
          });
      } else {
          mapRef.current.addLayer({
              id: 'route',
              type: 'line',
              source: {
                  type: 'geojson',
                  data: {
                      type: 'Feature',
                      properties: {},
                      geometry: {
                          type: 'LineString',
                          coordinates: coordinates
                      }
                  }
              },
              layout: {
                  'line-join': 'round',
                  'line-cap': 'round'
              },
              paint: {
                  'line-color': '#3b9ddd',
                  'line-width': 5
              }
          });
      }
  };

  useEffect(() => {
      if (mapContainerRef.current && !mapRef.current) {
          mapRef.current = new mapboxgl.Map({
              container: mapContainerRef.current,
              style: 'mapbox://styles/mapbox/streets-v11',
              center: [delivery.longitude, delivery.latitude],
              zoom: 14,
          });

          // Marker for order location
          const deliveryMarker = new mapboxgl.Marker({ color: 'red' })
              .setLngLat([delivery.longitude, delivery.latitude])
              .addTo(mapRef.current);
          setDeliveryMarker(deliveryMarker);
      }
  }, [delivery]);

  useEffect(() => {
    if (mapRef.current) {
      initializeDriverMarker();
    }
  }, [driver]);

  useEffect(() => {
    const fetchDriverLocation = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
          params: {
            driver_id: delivery.driver_id, // Send the driver_id as a query parameter
          },
        });
        console.log('Driver location:', response.data);

        // Update the driver state with the new data
        setDriver(response.data);
      } catch (error) {
        console.error('Error fetching driver location:', error);
      }
    };

    // Fetch the driver location every 4 seconds
    const intervalId = setInterval(fetchDriverLocation, 4000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const initializeDriverMarker = async () => {
    // Clear existing marker if it exists
    if (driverMarker.current) {
      driverMarker.current.remove();
    }
  
    if (driver && driver.longitude && driver.latitude) {
      // Create a DOM element for the marker
      const logoElement = document.createElement('div');
      logoElement.innerHTML = `<img src="${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png" alt="Logo" style="height: 30px; width: 30px;" />`;
  
      // Create a new marker for the driver
      const marker = new mapboxgl.Marker({
        element: logoElement
      })
        .setLngLat([driver.longitude, driver.latitude])
        .setPopup(new mapboxgl.Popup().setHTML(`
          <div>
            <h3 style="color: black; display: inline; font-size: 22px;">${driver.name}</h3>
            <span style="cursor: pointer; display: inline-block; margin-left: 10px;" onclick="window.open('https://wa.me/51${driver.email}', '_blank')">
            </span>
          </div>
        `))
        .addTo(mapRef.current);
  
      // Set the marker in the ref for future updates
      driverMarker.current = marker;

      // Fetch the route between the driver and the delivery point
      if (delivery && delivery.longitude && delivery.latitude) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get_route`, {
            start_point: [driver.longitude, driver.latitude],
            end_point: [delivery.longitude, delivery.latitude]
          });

          // Extract the route coordinates from the response
          const routeCoordinates = response.data.routes[0].legs[0].points.map(point => [point.longitude, point.latitude]);

          // Draw the route on the map
          drawRoute(routeCoordinates);

        } catch (error) {
          console.error("Error fetching route: ", error);
        }
      }
    }
  };

  // // Function to update the driver's location marker
  // const updateCurrentLocationMarker = async (position) => {
  //     const { latitude, longitude } = position.coords;

  //     if (currentLocationMarker) {
  //         currentLocationMarker.setLngLat([longitude, latitude]); // Update existing marker position
  //     } else {
  //         // Create a DOM element for the logo
  //         const logoElement = document.createElement('div');
  //         logoElement.innerHTML = `<img src="${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png" alt="Logo" style="height: 30px; width: 30px;" />`;

  //         // Create the marker using the logo element
  //         const marker = new mapboxgl.Marker({
  //             element: logoElement
  //         })
  //             .setLngLat([longitude, latitude])
  //             .addTo(mapRef.current);

  //         setCurrentLocationMarker(marker);
  //     }

  //     // Recalculate bounds and ensure the map is focused on the driver's marker with an offset
  //     if (deliveryMarker) {
  //         const bounds = new mapboxgl.LngLatBounds();
  //         bounds.extend([longitude, latitude]);
  //         bounds.extend([delivery.longitude, delivery.latitude]);

  //         // Adjust center to focus on the driver with an offset, making it appear that the driver is heading towards the order
  //         mapRef.current.flyTo({
  //             center: [longitude, latitude],
  //             offset: [0, 50],  // Push the marker down the screen, making it appear as if driving towards the order
  //             duration: 2500,
  //         });

  //         // Fetch the route from the server and draw it on the map
  //         try {
  //             const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get_route`, {
  //                 start_point: [longitude, latitude],
  //                 end_point: [delivery.longitude, delivery.latitude]
  //             });

  //             const routeCoordinates = response.data.routes[0].legs[0].points.map(point => [point.longitude, point.latitude]);
  //             drawRoute(routeCoordinates);
  //         } catch (error) {
  //             console.error("Error fetching route: ", error);
  //         }
  //     }
  // };

  // Start watching the user's location and update the marker
  // useEffect(() => {
  //     if (navigator.geolocation) {
  //         const watchId = navigator.geolocation.watchPosition(
  //             updateCurrentLocationMarker,
  //             (error) => console.error("Error watching position: ", error),
  //             {
  //                 enableHighAccuracy: true,
  //                 timeout: 5000,
  //                 maximumAge: 0
  //             }
  //         );

  //         return () => navigator.geolocation.clearWatch(watchId); // Clear the watch when the component unmounts
  //     } else {
  //         console.error("Geolocation is not supported by this browser.");
  //     }
  // }, [currentLocationMarker, deliveryMarker]);

  const handleCallClick = () => {
    window.open(`tel:${delivery.recipient_phone}`, '_self');
  };

  // Function to initiate a WhatsApp chat
  const handleWhatsAppClick = () => {
    const message = `Hola, tengo una entrega para ${delivery.recipient_name} de parte de ${delivery.user_name}, ya llegue!`;
    window.open(`https://wa.me/${delivery.recipient_phone}?text=${message}`, '_blank');
  };

  const handleWhatsappClientClick = () => {
    const message = `Hola, ya llegue al punto de entrega, intento comunicarme con ${delivery.recipient_name} pero no obtengo respuesta.`;
    window.open(`https://wa.me/${delivery.user_phone}?text=${message}`, '_blank');
  };

  const handleDirectionsClick = () => {
    const { latitude, longitude } = delivery;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(googleMapsUrl, '_blank');
  };
    
  const handleCameraClick = async () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.capture = 'camera';
    fileInput.multiple = true;

    fileInput.onchange = async () => {
        const selectedFiles = Array.from(fileInput.files);

        try {
            // Compress the images
            const compressedImages = await Promise.all(selectedFiles.map(async (file) => {
                const compressedFile = await compressImage(file, 0.6);
                return compressedFile;
            }));

            setImages([...images, ...compressedImages]);
        } catch (error) {
            console.error("Error compressing images: ", error);
        }
    };

    fileInput.click();
};

const handleAddressClick = () => {
  window.alert(`Direccion: ${delivery.address}, Notas: ${delivery.notes}`);
};

const handleMessageClick = async (id, type) => {
  try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_tres_minutos`, {
          id: id,
          type: type  // either 'order' or 'delivery'
      });

      if (response.status === 200) {
          console.log(`${type} tres_minutos updated successfully`);
          // Optionally, you can refresh the data or update the UI to reflect this change
          window.alert(`Llegas en 3 minutos`);
        } else {
          console.error(`Failed to update ${type} tres_minutos`);
      }
  } catch (error) {
      console.error(`Error updating ${type} tres_minutos:`, error);
  }
};


const handleYapeClick = () => {
  window.open(`https://yape.com/checkout/${delivery.charge}`, '_blank');
};

const handleOpenImageDialog = () => {
  setOpenImageDialog(true);
};

const handleCloseImageDialog = () => {
  setOpenImageDialog(false);
};


const handleSubmit = async () => {
  if (!note || !status || images.length === 0) {
    alert("Por favor asegúrese de proporcionar una nota, seleccionar un estado y cargar al menos una imagen antes de enviar.");
    return;
  }

  if (transfer && !charged) {
    alert("Por favor ingrese el monto a cobrar si es una transferencia.");
    return;
  }

  const formData = new FormData();
  formData.append('note', note);
  formData.append('status', status);
  formData.append('charged', charged);
  formData.append('transfer', transfer ? 'true' : 'false');
  images.forEach((image, index) => {
    formData.append('images', image, `image-${index}.jpg`);
  });

  setLoading(true);  // Set loading to true when submission starts

  try {
     
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/driver_delivery_submit/${delivery.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    console.log('Delivery submitted successfully:', response.data);
    onBackClick();  // Navigate back after successful submission
  } catch (error) {
    console.error('Error submitting order:', error);
    alert('There was an error submitting your order. Please try again.');
  } finally {
    setLoading(false);  // Set loading to false when submission is complete
  }
};


    return (
      <Box>
        {/* First Box - Customer Name and Address */}
        <Box
  sx={{
    height: '13vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    alignItems: 'center'  // Ensure the icon and text are centered vertically
  }}
>
  

  {/* Content Box with H2 and Back Icon */}
  <Box sx={{ width: '100%' }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <h2 style={{ margin: 0, color: 'black', flexGrow: 1 }}>
        {delivery.sequence} {delivery.recipient_name.length > 25 ? `${delivery.recipient_name.substring(0, 25)}...` : delivery.recipient_name}
      </h2>
      <ButtonBase onClick={onBackClick}>
        <ArrowBackIcon style={{ color: 'black' }} />
      </ButtonBase>
    </Box>
    <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: '5px', // Add some spacing between the title and details
          }}
        >
          {/* Order details */}
          <Box>
            <p style={{ margin: 0, color: 'black' }}>{delivery.user_name} {delivery.id} </p>
            <p style={{ margin: 0, color: 'black', fontSize: '1.1rem',  fontWeight: 'bold', }}>{delivery.charge ? `Cobrar: ${delivery.charge}` : ''}</p>
          </Box>

          {/* Arrival time on the right side */}
          <Box
            sx={{
              textAlign: 'right',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            <p style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
              <AccessAlarmIcon style={{ marginRight: '4px', color: 'black' }} />
              {formattedArrivalTime}
            </p>
          </Box>
        </Box>
  </Box>
</Box>
  
        {/* Second Box - Map */}
        <Box
        ref={mapContainerRef}
        sx={{
          height: '87vh',
          position: 'relative',
        }}
      >
        {loading ? (  // <-- Conditional rendering based on loading state
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <CircularProgress /> {/* <-- Show a loading spinner */}
            <p style={{ color: 'black' }}>Cargando, espera chamo...</p>
          </Box>
        ) : (
          // Map and other content...
          <Box>
            {/* The map will render here */}
          </Box>
        )}
      </Box>
        <IconButton
        style={{
          position: "absolute",
          bottom: "68%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleAddressClick}
        
      >
        <InfoIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "58%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleWhatsAppClick}
      >
        <WhatsAppIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "48%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleWhatsappClientClick}
      >
        <ContactPhoneIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "38%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={() => handleMessageClick(delivery.id, 'delivery')}
      >
        <AccessAlarmIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "28%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleOpenImageDialog}
      >
        <MonetizationOnIcon />
      </IconButton>
      <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
  <DialogTitle>
    <IconButton
      style={{ position: 'absolute', right: 8, top: 8 }}
      onClick={handleCloseImageDialog}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
    <img
      src={`https://fastmax.s3.amazonaws.com/edc9a2e2-4cb0-4699-8cc7-932a3621694c-image-0.jpg`}
      alt="Yape QR"
      style={{ width: '100%', height: 'auto' }}
    />
  </DialogContent>
</Dialog>
<IconButton
        style={{
          position: "absolute",
          bottom: "18%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleDirectionsClick}
      >
        <DirectionsIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "8%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleCallClick}
        
      >
        <CallIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "54%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleOpenChargedDialog}
      >
        <AttachMoneyIcon />
      </IconButton>
      <IconButton
  style={{
    position: "absolute",
    bottom: "44%",
    right: 30,
    zIndex: 1000,
    backgroundColor: "white",
    border: "2px solid black",
    borderRadius: "54%",
    color: "black",
    marginBottom: "10px",
    transform: "scale(1.3)",
  }}
  onClick={handleNoteClick} // <-- Open the dialog on click
>
  <NoteAddIcon />
</IconButton>
      
      <IconButton
        style={{
          position: "absolute",
          bottom: "34%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleCameraClick}
      >
        <CameraAltIcon />
      </IconButton>
      
        <IconButton
        style={{
          position: "absolute",
          bottom: "24%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleStatusClick}
      >
        <SettingsIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "10%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "80%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.7)",
        }}
        onClick={handleSubmit}
      >
        <CheckIcon />
      </IconButton>
      <Dialog open={openChargedDialog} onClose={handleCloseChargedDialog}>
        <DialogTitle>Cobro y Transferencia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor ingrese el monto a cobrar y seleccione si es una transferencia.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Monto Cobrado"
            type="number"
            fullWidth
            variant="outlined"
            value={charged}
            onChange={(e) => setCharged(e.target.value)}
            error={transfer && !charged}
            helperText={transfer && !charged ? 'El monto es obligatorio si se selecciona transferencia' : ''}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={transfer}
                onChange={(e) => setTransfer(e.target.checked)}
                color="primary"
              />
            }
            label="¿Es una transferencia a Fast Max?"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChargedDialog(false)}>Cancelar</Button>
          <Button onClick={handleChargedSubmit} disabled={transfer && !charged}>Guardar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openNoteDialog} onClose={() => setOpenNoteDialog(false)}>
                <DialogTitle>Agrega un comentario</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Porfavor ingrese una nota para el pedido.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nota"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenNoteDialog(false)}>Cancelar</Button>
                    <Button onClick={handleNoteSubmit}>Guardar</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Status Selection */}
            <Dialog open={openStatusDialog} onClose={() => setOpenStatusDialog(false)}>
                <DialogTitle> Estado</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        label=""
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        fullWidth
                        variant="outlined"
                    >
                        {statusOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenStatusDialog(false)}>Cancelar</Button>
                    <Button onClick={handleStatusSubmit}>Guardar</Button>
                </DialogActions>
            </Dialog>
      </Box>
    );
}

export default DeliveryDriver;
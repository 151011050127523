import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const FindUserMap = ({ deliveryId, onClose }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);

    useEffect(() => {
        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [-77.03711992646711, -12.060235303763926],  // Default center (Lima)
                zoom: 12
            });

            mapRef.current.on('click', (e) => {
                const { lng, lat } = e.lngLat;
                addMarker(lng, lat);
                setSelectedLocation({ latitude: lat, longitude: lng });
            });
        }
    }, []);

    const addMarker = (lng, lat) => {
        if (markerRef.current) {
            markerRef.current.remove();
        }
        markerRef.current = new mapboxgl.Marker()
            .setLngLat([lng, lat])
            .addTo(mapRef.current);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearch = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, {
                params: {
                    query: searchQuery
                }
            });
            setSearchResults(response.data.results);
        } catch (error) {
            console.error('Error searching locations:', error);
        }
    };

    const handleLocationSelect = (location) => {
        const { lat, lon } = location.position;
        mapRef.current.flyTo({ center: [lon, lat], zoom: 15 });
        addMarker(lon, lat);
        setSelectedLocation({ latitude: lat, longitude: lon });
        setSearchResults([]);
    };

    const handleSave = async () => {
        if (selectedLocation) {
            console.log("Sending selected location:", selectedLocation);
            try {
                const token = localStorage.getItem('token');
                await axios.post(`${process.env.REACT_APP_API_URL}/api/update/delivery_point_map`, {
                    id: deliveryId,
                    latitude: selectedLocation.latitude,
                    longitude: selectedLocation.longitude
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log('Location saved successfully:', selectedLocation);
                onClose();
            } catch (error) {
                console.error('Error saving location:', error);
            }
        } else {
            alert('Please select a location first.');
        }
    };

    return (
        <Box sx={{ position: 'absolute', top: "80px", left: "620px", width: '100%', maxWidth: 500, padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: 'white', zIndex: '3000'  }}>
            <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
                <CloseIcon />
            </IconButton>
            <TextField
                label="Busca una Direccion"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                sx={{ marginBottom: 2, width: '80%' }}
                InputProps={{
                    disableUnderline: true, // Removes the underline (border)
                    style: {
                        border: 'none' // Ensures no border is applied
                    }
                }}
                InputLabelProps={{
                    shrink: true, // Keeps the label visible even without border
                }}
            />
            <Button onClick={handleSearch} variant="contained" fullWidth>
                Buscar
            </Button>
            {searchResults.length > 0 && (
                <List>
                    {searchResults.map((result, index) => (
                        <ListItem button key={index} onClick={() => handleLocationSelect(result)}>
                            <ListItemText primary={result.address.freeformAddress} sx={{ color: 'black' }} />
                        </ListItem>
                    ))}
                </List>
            )}
            <Box
                ref={mapContainerRef}
                sx={{ height: '300px', marginTop: 2 }}
            />
            <Button onClick={handleSave} variant="contained" fullWidth sx={{ marginTop: 2 }}>
                Guardar Ubicacion
            </Button>
        </Box>
    );
};

export default FindUserMap;

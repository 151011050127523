import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddIcon from '@mui/icons-material/Add';
import { Box, TextField, List, ListItem, ListItemText } from '@mui/material';
import ReactMapGL, { Marker } from 'react-map-gl';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import EditionUserCard from './EditionUserCard';
import FindClientMap from './FindClientMap';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AddRecojo from './AddRecojo';
import AddEntrega from './AddEntrega';
import AddMasivo from './AddMasivo';    
import axios from 'axios';  // Import axios

const SearchUserResultCard = ({ user, onClose }) => {
    const { id, addresses, phone_number, email, username } = user;
    console.log('User:', user);
    const [viewport, setViewport] = React.useState({
        latitude: addresses?.[0]?.latitude || -12.0464,
        longitude: addresses?.[0]?.longitude || -77.0428,
        zoom: 14,
        width: '100%',
        height: '100%'
    });

    const [showEditionCard, setShowEditionCard] = useState(false);
    const [showMapUserCard, setShowMapUserCard] = useState(false);
    const [showAddRecojoCard, setShowAddRecojoCard] = useState(false);
    const [showAddEntregaCard, setShowAddEntregaCard] = useState(false);
    const [showMasivoCard, setShowMasivoCard] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [entregas, setEntregas] = useState([]);
    const [recojos, setRecojos] = useState([]);

    useEffect(() => {
        if (selectedDate) {
            fetchDeliveries();
        }
    }, [selectedDate]);

    const fetchDeliveries = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user_deliveries`, {
                params: {
                    user_id: id,
                    date: selectedDate
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setEntregas(response.data.entregas);
            console.log('Entregas:', response.data.entregas);
            setRecojos(response.data.recojos);
            console.log('Recojos:', response.data.recojos);
        } catch (error) {
            console.error('Error fetching deliveries:', error);
        }
    };

    const handleDeliveryClick = (delivery) => {
        setSelectedDelivery(delivery);
    };

    const handleEditClick = () => {
        setShowEditionCard(true);
    };

    const handleCloseEditionCard = () => {
        setShowEditionCard(false);
    };


    const handleCloseAddRecojoCard = () => {
        setShowAddRecojoCard(false);
    };

    const handleAddRecojoClick = () => {
        setShowAddRecojoCard(true);
    };

    const handleCloseAddEntregaCard = () => {
        setShowAddEntregaCard(false);
    };

    const handleAddEntregaClick = () => {
        setShowAddEntregaCard(true);
    };

    const handleCloseAddMasivo = () => {
        setShowMasivoCard(false);
    };

    const handleAddMasivoClick = () => {
        setShowMasivoCard(true);
    };




    const handleDeleteClick = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (!confirmDelete) return;
    
        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete_user/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                alert('User deleted successfully');
                onClose();  // Close the card after deletion
            } else {
                alert('Failed to delete user');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            alert('Error deleting user');
        }
    };

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/51${phone_number}`, '_blank');
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Card sx={{ maxWidth: 800, height: 650, position: 'relative', top: "80px", left: "10px", zIndex: '2500' }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {username} {id} {phone_number}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                             {email}
                        </Typography>
                        <Box>
                            <IconButton size="small" onClick={handleAddMasivoClick}>
                                <AttachFileIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleAddEntregaClick}>
                                <AddLocationAltIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleAddRecojoClick}>
                                <AddIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleWhatsAppClick}>
                                <WhatsAppIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleEditClick}>
                                <EditIcon />
                            </IconButton>
                            <IconButton size="small" onClick={handleDeleteClick}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: 2 }}>
                        <TextField
                            label="Pick Date"
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </CardContent>
                
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '420px', marginTop: 2 }}>
                <Box sx={{ width: '30%', maxHeight: '400px', overflowY: 'auto' }}>
                        <Typography variant="h6">Lista de Recojos</Typography>
                        <List>
                            {recojos.map((recojo) => (
                                <ListItem button key={recojo.id} onClick={() => handleDeliveryClick(recojo)}>
                                    <ListItemText primary={`Recojo ID: ${recojo.id} - ${recojo.status}`} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="h6">Lista de Entregas</Typography>
                        <List>
                            {entregas.map((entrega) => (
                                <ListItem button key={entrega.id} onClick={() => handleDeliveryClick(entrega)}>
                                    <ListItemText primary={`ID: ${entrega.id} - ${entrega.status}`} />
                                    
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Typography variant="h6">Detalles</Typography>
                        {selectedDelivery ? (
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    ID: {selectedDelivery.id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Estado: {selectedDelivery.status}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    User ID: {selectedDelivery.user_id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Nombre: {selectedDelivery.recipient_name}
                                </Typography>
                                
                                <Typography variant="body2" color="text.secondary">
                                    Telefono: {selectedDelivery.recipient_phone}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Notas: {selectedDelivery.notes}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Distrito: {selectedDelivery.distric}
                                </Typography>
                            </Box>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No delivery selected.
                            </Typography>
                        )}
                    </Box>
                    
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <ReactMapGL
                            {...viewport}
                            mapboxApiAccessToken="YOUR_MAPBOX_ACCESS_TOKEN"
                            onViewportChange={(nextViewport) => setViewport(nextViewport)}
                            mapStyle="mapbox://styles/mapbox/streets-v11"
                        >
                            {addresses.map((address) => (
                                <Marker key={address.id} latitude={address.latitude} longitude={address.longitude}>
                                    <img src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`} alt="Location Marker" style={{ height: 30, width: 30 }} />
                                </Marker>
                            ))}
                        </ReactMapGL>
                    </Box>
                
                </Box>

                <IconButton
                    size="medium"
                    onClick={onClose}
                    style={{ position: 'absolute', top: 7, right: 7, backgroundColor: 'white', zIndex: 1 }}
                >
                    <CloseIcon />
                </IconButton>
            </Card>

            {showEditionCard && (
                <EditionUserCard user={user} onClose={handleCloseEditionCard} />
            )}
            {showAddRecojoCard && user && (
                <AddRecojo onClose={handleCloseAddRecojoCard} user_id={id} user={user} />
            )}
            {showAddEntregaCard && (
                <AddEntrega onClose={handleCloseAddEntregaCard} user_id={id} />
            )}
            {showMasivoCard && (
                <AddMasivo onClose={handleCloseAddMasivo} userId={id} />
            )}
        </Box>
    );
};

export default SearchUserResultCard;
import * as XLSX from 'xlsx';

export const DownloadExcelResumen = (deliveryPoints) => {
    // Map delivery points data to an array of arrays for Excel
    const data = [
        ["ID", "Nombre", "Direccion", "Telefono", "Notas", "Distrito", "Fecha de entrega", "Cobrar", "Cobrado", "Transfer a FastMax", "Transfer a Cliente", "Notas Conductor", "Estado"],
        ...deliveryPoints.map(dp => [
            dp.id, dp.recipient_name, dp.address, dp.recipient_phone, dp.notes, dp.distric, dp.delivery_date, dp.charge, dp.charged, dp.transfer, dp.transfer_client, dp.notes_driver, dp.status
        ])
    ];

    // Create a new workbook and add the data to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resumen");

    // Write the workbook and download it
    XLSX.writeFile(wb, `Delivery_Resumen.xlsx`);
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, IconButton, ListItemIcon, ListItemSecondaryAction, ButtonBase } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditUserDetail from './EditUserDetail';

function MyAccount({ userId, onOptionClick, onBackClick, onLogout }) {
    const [user, setUser] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [editingValue, setEditingValue] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_API_URL}/api/user_edit/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setUser(response.data.user);
            })
            .catch(error => console.error('Error fetching user data:', error));
    }, [userId]);

    const handleEditClick = (field, currentValue) => {
        setEditingField(field);
        setEditingValue(currentValue); 
    }

    const handleChange = (event) => {
        setEditingValue(event.target.value);
    };

    const handleSubmit = async (newValue) => {
        const requestBodyKeyMap = {
            username: 'username',
            email: 'email',
            phoneNumber: 'phone_number',
            password_hash: 'password_hash',
        };
        const requestBodyKey = requestBodyKeyMap[editingField];
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user_edit/${userId}`, { [requestBodyKey]: newValue }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUser(response.data.user);
            setEditingField(null);
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    if (!user) {
        return <div>Loading...</div>; // or a more sophisticated loading indicator
    }

    return (
        <div>
            <ButtonBase onClick={() => { onBackClick(); onOptionClick('back'); }} style={{ padding: '20px' }}>
                <ArrowBackIcon style={{ color: 'black' }}/>
            </ButtonBase>
            <Avatar sx={{ width: 60, height: 60, margin: '0 auto' }}>
                <PersonIcon sx={{ width: 40, height: 40 }} />
            </Avatar>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    {editingField === 'username' ? (
                        <EditUserDetail currentValue={editingValue} onChange={handleChange} onSubmit={handleSubmit} />
                    ) : (
                        <ListItemText primary={user.username} style={{ color: 'black' }} />
                    )}
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleEditClick('username', user.username)} >
                            <EditIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PhoneIcon />
                    </ListItemIcon>
                    {editingField === 'phoneNumber' ? (
                        <EditUserDetail currentValue={editingValue} onChange={handleChange} onSubmit={handleSubmit} />
                    ) : (
                        <ListItemText primary={user.phone_number} style={{ color: 'black' }} />
                    )}
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleEditClick('phoneNumber', user.phone_number)}>
                            <EditIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemIcon >
                        <EmailIcon />
                    </ListItemIcon>
                    {editingField === 'email' ? (
                        <EditUserDetail currentValue={editingValue} onChange={handleChange} onSubmit={handleSubmit} />
                    ) : (
                        <ListItemText primary={user.email} style={{ color: 'black' }}/>
                    )}
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleEditClick('email', user.email)} >
                            <EditIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemIcon >
                        <KeyIcon />
                    </ListItemIcon>
                    {editingField === 'password_hash' ? (
                        <EditUserDetail currentValue={editingValue} onChange={handleChange} onSubmit={handleSubmit} />
                    ) : (
                        <ListItemText primary={user.password_hash} style={{ color: 'black' }}/>
                    )}
                    <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleEditClick('password_hash', user.password_hash)} >
                            <EditIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            
            <Divider />
            <List>
                <ListItem button onClick={onLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Salir" style={{ color: 'black' }} />
                </ListItem>
                {/* <ListItem>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Delete account" style={{ color: 'black' }} />
                </ListItem> */}
            </List>
        </div>
    );
}

export default MyAccount;

import React from 'react';
import { ButtonBase, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaymentIcon from '@mui/icons-material/Payment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import InfoIcon from '@mui/icons-material/Info';

function OptionsDriver({ userId, username, onOptionClick, onBackClick, onLogout }) {
    return (
        <div>
            <ButtonBase onClick={() => { onBackClick() }} style={{ padding: '20px', color: 'black' }}>
                <ArrowBackIcon />
            </ButtonBase>
            <List>
                <ListItem button onClick={() => onOptionClick('my-account')} style={{ padding: '20px' }}>
                    <ListItemAvatar>
                        <Avatar></Avatar>
                    </ListItemAvatar>
                    {username && <ListItemText primary={username} secondary="Mi cuenta" style={{ color: 'black', fontWeight: 'bold' }} />}
                </ListItem>
            </List>
            <Divider variant="middle" />
            <List>
                <ListItem button onClick={() => onOptionClick('support')} style={{ padding: '20px' }}>
                    <SupportAgentIcon style={{ color: 'lightgray', marginRight: '10px' }} />
                    <ListItemText primary="Support" style={{ color: 'black' }} />
                </ListItem>
                <ListItem button onClick={onLogout} style={{ padding: '20px' }}>
                    <InfoIcon style={{ color: 'lightgray', marginRight: '10px' }} />
                    <ListItemText primary="Cerrar Sesion" style={{ color: 'black' }}/>
                </ListItem>
            </List>
        </div>
    );
}

export default OptionsDriver;
import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, MenuItem } from '@mui/material';
import axios from 'axios';

const Search = ({ onSearch, onClose }) => {
    const [searchType, setSearchType] = useState('entrega_id');
    const [query, setQuery] = useState('');

    const handleSearch = async () => {
        try {
            let response;

            if (searchType === 'entrega_id') {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/delivery_point`, {
                    params: { entrega_id: query },
                });
            } else if (searchType === 'recojo_id') {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/order`, {
                    params: { recojo_id: query },
                });
            } else if (searchType === 'user') {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/user`, {
                    params: { id: query },
                });
            } else if (searchType === 'driver') {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/user`, {
                    params: { id: query },
                });
            }

            if (response && response.data) {
                console.log('API response:', response.data);
                onSearch(response.data, searchType); // Pass the search type along with the result
            }
        } catch (error) {
            console.error(`Error fetching ${searchType}:`, error);
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Search
                </Typography>
                <TextField
                    select
                    label="Search by"
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    <MenuItem value="entrega_id">Entrega ID</MenuItem>
                    <MenuItem value="recojo_id">Recojo ID</MenuItem>
                    <MenuItem value="user">Usuario ID</MenuItem>
                    <MenuItem value="driver">Driver ID</MenuItem>
                    {/* Add other search types here if needed */}
                </TextField>
                <TextField
                    label="Query"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button
                    variant="contained"
                    onClick={handleSearch}
                    sx={{ mt: 2 }}
                    fullWidth
                >
                    Buscar
                </Button>
            </Box>
        </Modal>
    );
};

export default Search;
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

function EditUserDetail({ currentValue, onSubmit, onChange }) {
  const [value, setValue] = useState(currentValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(value);
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
      <TextField 
        type="text" 
        value={value} 
        onChange={handleChange} 
        size="small" 
        style={{ marginRight: '10px' }} 
      />
      <IconButton type="submit" >
        <CheckIcon />
      </IconButton>
    </form>
  );
}

export default EditUserDetail;

import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Carousel from 'react-material-ui-carousel';
import { Box, List, ListItem, ListItemText, Button, TextField } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';

const SearchDriverResultCard = ({ user, onClose }) => {
    const { id, phone_number, email, username } = user;
    const [entregas, setEntregas] = useState([]);
    const [recojos, setRecojos] = useState([]);
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [total_deposit, setTotalDeposit] = useState(0);
    const [images, setImages] = useState([]);
    console.log('selectedDelivery:', selectedDelivery);

    useEffect(() => {
        if (selectedDate) {
            fetchDeliveries();
        }
    }, [selectedDate]);

    useEffect(() => {
        const calculateTotalDeposit = () => {
            const total = entregas.reduce((sum, entrega) => {
                // Add only if transfer is false and charged is a valid number
                return entrega.transfer === false ? sum + (entrega.charged || 0) : sum;
            }, 0);
            setTotalDeposit(total);  // Update the total deposit state
        };
    
        calculateTotalDeposit();  // Call the function to calculate the total
    }, [entregas]);

    const fetchDeliveries = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/driver_deliveries`, {
                params: {
                    driver_id: id,
                    date: selectedDate
                },
            });

            setEntregas(response.data.entregas);
            console.log('Entregas:', response.data.entregas);
            setRecojos(response.data.recojos);
            console.log('Recojos:', response.data.recojos);
        } catch (error) {
            console.error('Error fetching deliveries:', error);
        }
    };

    const handleWhatsAppClick = () => {
        window.open(`https://wa.me/51${phone_number}`, '_blank');
    };

    const handleDeliveryClick = (delivery) => {
        setSelectedDelivery(delivery);
        setImages(delivery.image_urls || []);  // Assuming delivery.image_urls is an array of image URLs
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Card sx={{ maxWidth: 1000, height: 650,  position: 'relative', top: "80px", left: "10px", zIndex: '2000' }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {username} {id} {phone_number}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                             {email} {total_deposit}
                        </Typography>
                        <Box>
                            <IconButton size="small" onClick={handleWhatsAppClick}>
                                <WhatsAppIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    
                    <Box sx={{ marginTop: 2 }}>
                        <TextField
                            label="Pick Date"
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </CardContent>
                
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '420px', marginTop: 2 }}>
                    <Box sx={{ width: '30%', maxHeight: '400px', overflowY: 'auto' }}>
                        <Typography variant="h6">Lista de Recojos</Typography>
                        <List>
                            {recojos.map((recojo) => (
                                <ListItem button key={recojo.id} onClick={() => handleDeliveryClick(recojo)}>
                                    <ListItemText primary={`ID: ${recojo.id} - ${recojo.status} - ${recojo.sequence}`} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="h6">Lista de Entregas</Typography>
                        <List>
                            {entregas.map((entrega) => (
                                <ListItem button key={entrega.id} onClick={() => handleDeliveryClick(entrega)}>
                                    <ListItemText primary={`ID: ${entrega.id} - ${entrega.status} - ${entrega.sequence}`} />
                                    
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Typography variant="h6">Detalles</Typography>
                        {selectedDelivery ? (
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    ID: {selectedDelivery.id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Estado: {selectedDelivery.status}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    User ID: {selectedDelivery.user_id}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Nombre: {selectedDelivery.recipient_name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Cobrar: {selectedDelivery.charge}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Transferencia FM: {selectedDelivery.transfer.toString()}
                                </Typography>
                                
                                <Typography variant="body2" color="text.secondary">
                                    Cobrado: {selectedDelivery.charged}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Notas: {selectedDelivery.notes}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Notas Driver: {selectedDelivery.notes_driver}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Distrito: {selectedDelivery.distric}
                                </Typography>
                            </Box>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No delivery selected.
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ width: '35%', height: '100%' }}>
                        <Typography variant="h6">Imagenes</Typography>
                        {images.length > 0 ? (
                            <Carousel>
                                {images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Delivery Image ${index + 1}`}
                                        style={{ width: '100%', height: '350px', objectFit: 'cover' }}
                                    />
                                ))}
                            </Carousel>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No images disponibles.
                            </Typography>
                        )}
                    </Box>
                </Box>

                <IconButton
                    size="medium"
                    onClick={onClose}
                    style={{ position: 'absolute', top: 7, right: 7, backgroundColor: 'white', zIndex: 1 }}
                >
                    <CloseIcon />
                </IconButton>
            </Card>
        </Box>
    );
};

export default SearchDriverResultCard;

import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DownloadExcelTemplate } from '../user/DownloadExcelTemplate';
import { Box, Typography, Button, Card } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddMasivo = ({ onClose, userId }) => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const onDrop = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleUpload = async () => {
        if (!file) {
            setMessage('Porfavor selecciona un archivo primero.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', userId);  // Add user_id to the form data

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/delivery_points/bulk_upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.error || 'Upload failed.');
        }
    };

    return (
        <Card sx={{ maxWidth: 300, position: 'absolute', top: "80px", left: "820px", maxHeight: "450px", overflowY: 'auto', }}>
        <Box sx={{ padding: 3 }}>
            {/* Back Arrow */}
            <IconButton onClick={onClose} style={{ marginBottom: '20px', color: 'black' }}>
                <ArrowBackIcon />
            </IconButton>

            {/* Dropzone */}
            <div
                {...getRootProps()}
                style={{
                    backgroundColor: 'white',
                    border: '2px dashed black',
                    borderRadius: '8px',
                    // padding: '20px',
                    textAlign: 'center',
                    marginBottom: '20px',
                    cursor: 'pointer',
                }}
            >
                <input {...getInputProps()} />
                <DriveFolderUploadIcon style={{
                    fontSize: '50px',
                    color: 'black',
                }} />
                <Typography variant="body1" style={{ color: 'black' }}>Arrastra un archivo aquí</Typography>
            </div>

            {/* Download Template Button */}
            <Button
                variant="contained"
                onClick={DownloadExcelTemplate}
                startIcon={<FileDownloadIcon />}
                sx={{ marginBottom: 2, backgroundColor: 'black' }}
            >
                Descargar plantilla de Excel
            </Button>

            {/* Upload Button */}
            <Button
                variant="contained"
                onClick={handleUpload}
                startIcon={<CheckCircleIcon />}
                sx={{ backgroundColor: 'green', color: 'white', marginBottom: 2 }}
            >
                Subir Entregas
            </Button>

            {/* Message */}
            {message && (
                <Box sx={{ marginTop: 2, color: message.includes('failed') ? 'red' : 'green' }}>
                    <Typography variant="body1">{message}</Typography>
                </Box>
            )}
        </Box>
        </Card>
    );
};

export default AddMasivo;
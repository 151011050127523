import axios from 'axios';
import { useEffect } from 'react';

const useTokenRefresh = (isUserLoggedIn) => {
  useEffect(() => {
    if (!isUserLoggedIn) return;

    const interval = setInterval(async () => {
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/refresh`, {}, {
            headers: { Authorization: `Bearer ${refreshToken}` },
          });
          localStorage.setItem('token', response.data.access_token);  // Update token
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    }, 14 * 60 * 1000); // Refresh the token every 14 minutes

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [isUserLoggedIn]); // Add isUserLoggedIn as a dependency
};

export default useTokenRefresh;
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Box, TextField, Typography, List, ListItem, ListItemText } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const ResumenDriver = ({ onBackClick, userId }) => {
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [totalCashCollected, setTotalCashCollected] = useState(0);
    

    useEffect(() => {
        const fetchResumenData = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resumen_data/driver`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    params: { driver_id: userId }
                });
                console.log("Fetched Resumen Data:", response.data);
                setOrders(response.data.recojos);
                setDeliveryPoints(response.data.entregas);

                // Calculate total cash collected
                const totalCash = response.data.entregas
                    .filter(deliveryPoint => !deliveryPoint.transfer) // Only include those with transfer === false
                    .reduce((acc, deliveryPoint) => acc + (deliveryPoint.charged || 0), 0);
                
                setTotalCashCollected(totalCash);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };
    
        fetchResumenData();
    }, [userId]);

    const handleOrderClick = (order) => {
        setSelectedOrder(order);
    };

    const handleDeliveryPointClick = (deliveryPoint) => {
        setSelectedOrder(deliveryPoint);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", padding: 2 }}>
            <Box sx={{ marginBottom: 2 }}>
                <ArrowBackIcon onClick={onBackClick} style={{ color: 'black' }}/>
            </Box>

            {/* Summary Section */}
            <Box sx={{ textAlign: "center", marginBottom: 3 }}>
                <Typography variant="h4" color="text.primary">
                    Depositar: S/.{totalCashCollected.toFixed(2)} 
                </Typography>
                <Typography variant="body1" color="text.primary">
                    Recojos: {orders.length}
                </Typography>
                <Typography variant="body1" color="text.primary">
                    Entregas: {deliveryPoints.length}
                </Typography>
            </Box>

            {/* Lists Section */}
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Box sx={{ flex: 1, overflowY: "auto", padding: 2, maxHeight: '300px', }}>
                    <Typography variant="h6" color="text.primary">Lista de Entregas</Typography>
                    <List>
                        {deliveryPoints.map((deliveryPoint) => (
                            <ListItem key={deliveryPoint.id}>
                                <ListItemText
                                    primary={`Id: ${deliveryPoint.id} - ${deliveryPoint.sequence} - ${deliveryPoint.recipient_name.substring(0, 15)} - ${deliveryPoint.status}${deliveryPoint.charged && deliveryPoint.transfer === false ? ` - ${deliveryPoint.charged} PEN` : ''}`}
                                    primaryTypographyProps={{ color: "text.primary" }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                
                <Box sx={{ flex: 1, overflowY: "auto",  padding: 2, borderBottom: "1px solid #ccc" }}>
                    <Typography variant="h6" color="text.primary">Lista de Recojos</Typography>
                    <List>
                        {orders.map((order) => (
                            <ListItem key={order.id}>
                                <ListItemText
                                    primary={`ID: ${order.id}  ${order.customer_name} - ${order.status}`}
                                    primaryTypographyProps={{ color: "text.primary" }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>

                
            </Box>
        </Box>
    );
};

export default ResumenDriver;
import React, { useState } from "react";
import axios from 'axios';
import Input from "./Input";

function Login({ onLoginSuccess, setIsUserRegistered }) {
  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData(prevLoginData => ({
      ...prevLoginData,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, loginData);
      if (response.data.access_token && response.data.refresh_token) {  // Ensure there's an access_token in the response
        localStorage.setItem('token', response.data.access_token);  // Store the token
        localStorage.setItem('refresh_token', response.data.refresh_token);  // Store the refresh token
        localStorage.setItem('user_id', response.data.user_id);  // Store the user ID
        localStorage.setItem('username', response.data.username);  // Store the username
        localStorage.setItem('email', response.data.email);  // Store the email
        localStorage.setItem('phone_number', response.data.phone_number);  // Store the phone number
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('addresses', JSON.stringify(response.data.addresses));
        
        onLoginSuccess(response.data.user_id, response.data.username, response.data.email, response.data.phone_number, response.data.role, response.data.addresses);  // Call onLoginSuccess with the user ID
      } else {
        console.error("No token received");
      }
    } catch (error) {
      if (error.response) {
        console.error("Login failed:", error.response.data.message);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0'
      }}
    >
      <div style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}>
        <img
          src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
          alt="Logo"
          style={{ height: "58px", width: "58px", marginBottom: '20px' }}
        />
        <form
          className="form"
          onSubmit={handleSubmit}
          style={{
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            textAlign: 'center'
          }}
        >
          <h2 style={{ marginBottom: '20px', fontSize: '24px', color: '#333' }}>Login</h2>
          <Input
            name="email"
            type="text"
            placeholder="Email"
            value={loginData.email}
            onChange={handleChange}
            required
            style={{
              width: '100%',
              padding: '10px',
              margin: '10px 0',
              border: '1px solid #ddd',
              borderRadius: '5px',
              fontSize: '16px',
              boxSizing: 'border-box',
              color: 'black' // Set text color to black
            }}
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            value={loginData.password}
            onChange={handleChange}
            required
            style={{
              width: '100%',
              padding: '10px',
              margin: '10px 0',
              border: '1px solid #ddd',
              borderRadius: '5px',
              fontSize: '16px',
              boxSizing: 'border-box',
              color: 'black' // Set text color to black
            }}
          />
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#000',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontSize: '18px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#333'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#000'}
          >
            Entrar
          </button>
          <p style={{ marginTop: '15px', color: '#777' }}>
            No tienes cuenta?{' '}
            <a
              href="#"
              onClick={() => setIsUserRegistered(false)}
              style={{
                color: '#000',
                textDecoration: 'none',
                fontWeight: 'bold'
              }}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
              Regístrate aquí
            </a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
import React, { useRef, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Fab, getIconUtilityClass } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import MapIcon from '@mui/icons-material/Map';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import * as turf from '@turf/turf';



function RoutesAdmin({  onBackClick, userId }) {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const drawingManagerRef = useRef(null);
    const atlas = window.atlas;
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [pickupPoints, setPickupPoints] = useState([]);
    const [driverId, setDriverId] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [route, setRoute] = useState(null);
    const [isDelivery, setIsDelivery] = useState(true);
    
    const [selectedMarkerIds, setSelectedMarkerIds] = useState([]);
    const [driverIdInput, setDriverIdInput] = useState('');

    const createRoute = async (startPoint, endPoint, stops) => {
        const coordinates = [startPoint, ...stops, endPoint].map(coord => coord.join(',')).join(':');
        console.log('Coordinates:', coordinates);
        const subscriptionKey = process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY; // Replace with your actual Azure Maps subscription key
    
        const url = 'https://atlas.microsoft.com/route/directions/json';
        const params = {
            'api-version': '1.0',
            'query': coordinates,
            'subscription-key': subscriptionKey,
            'routeType': 'fastest',
            'optimize': 'timeWithTraffic'
        };
    
        // Log the URL and parameters to debug
        console.log('Request URL:', url);
        console.log('Request Parameters:', params);
    
        try {
            const response = await axios.get(url, { params });
            console.log('Response:', response.data);
            return response.data.routes[0];
        } catch (error) {
            if (error.response) {
                console.error('Error creating route:', error.response.data);
            } else {
                console.error('Error creating route:', error.message);
            }
            throw error;
        }
    };
    
    
    const fetchDeliveryPoints = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/admin`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log("Fetched delivery points:", response.data);
            setDeliveryPoints(response.data);
        } catch (error) {
            console.error("Error fetching delivery points:", error);
        }
    };

    const fetchPickupPoints = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_orders/admin`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log("Fetched pickup points:", response.data);
            setPickupPoints(response.data);
        } catch (error) {
            console.error("Error fetching pickup points:", error);
        }
    };

    const fetchDrivers = async () => {  
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/driver/location`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log("Fetched drivers:", response.data);
            setDrivers(response.data);
        } catch (error) {
            console.error("Error fetching drivers", error);
        }
    };

    const handleEntregasClick = async () => {
        console.log("Fetching delivery points...");
        setIsDelivery(true);
        await fetchDeliveryPoints();
        await fetchDrivers();
        initializeDrawingManager();
    };

    const handleRecojosClick = async () => {
        console.log("Fetching orders...");
        setIsDelivery(false);
        await fetchPickupPoints();
        await fetchDrivers();
        initializeDrawingManager();
    };

    const handleDriverClick = (driverId) => {
        // Assuming deliveryPoints is an array of all delivery points
        const points = isDelivery ? deliveryPoints : pickupPoints;
        const matchingPoints = points.filter(point => point.driver_id === driverId);
    
        // Clear existing markers
        mapRef.current.markers.clear();
        const colorMap = {
            cancel: 'red',
            success: 'green',
            default: 'black'
        };
        
        // Add new markers for matching delivery points
        matchingPoints.forEach(point => {
            const color = colorMap[point.status] || colorMap.default;
            const marker = new atlas.HtmlMarker({
                position: [point.longitude, point.latitude],
                htmlContent: `
                    <div style="position: relative; width: 40px; height: 50px;">
                        <div style="width: 35px; height: 35px; border-radius: 50%; background-color: green; display: flex; align-items: center; justify-content: center; font-weight: bold; color: white;">
                            ${point.id}-${point.sequence}
                        </div>
                    </div>
                `
                // Add any additional marker properties here
            });
            marker.setOptions({ properties: { pointId: point.id } });
    
            mapRef.current.markers.add(marker);
    
            // Add event listener for the marker click
            mapRef.current.events.add('click', marker, () => {
                const seqValue = prompt('Enter sequence number:');
                if (seqValue) {
                    updateSequence(point.id, seqValue);
                }
            });
        });
    };

    useEffect(() => {
        const loadScripts = async () => {
            console.log("Loading Azure Maps scripts...");
            await loadScript('https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.js');
            await loadCSS('https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.css');
            console.log("Azure Maps main scripts loaded.");

            await loadScript('https://atlas.microsoft.com/sdk/javascript/drawing/1/atlas-drawing.min.js');
            await loadCSS('https://atlas.microsoft.com/sdk/javascript/drawing/1/atlas-drawing.min.css');
            console.log("Azure Maps drawing tools scripts loaded.");

            initializeMap();
            
        };

        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        };
    
        const loadCSS = (href) => {
            return new Promise((resolve, reject) => {
                const link = document.createElement('link');
                link.href = href;
                link.rel = 'stylesheet';
                link.onload = resolve;
                link.onerror = reject;
                document.head.appendChild(link);
            });
        };

        loadScripts();
    }, []);
    
    const initializeMap = () => {
        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = new atlas.Map(mapContainerRef.current, {
                center: [-77.0428, -12.0464],
                zoom: 12,
                authOptions: {
                    authType: 'subscriptionKey',
                    subscriptionKey: 'Achm_e9abrKvupF8HjuPnaMAEiTBavpXTPj30Q8CO4U'
                }
            });
    
            mapRef.current.events.add('ready', () => {
                console.log('Map initialized');
                updateMarkers();
                
            });
        }
    };

    
    
    const initializeDrawingManager = () => {
        if (window.atlas && window.atlas.drawing && mapRef.current) {
            console.log("Initializing drawing tools");
    
            // Create and configure the drawing toolbar
            const drawingToolbar = new window.atlas.control.DrawingToolbar({
                buttons: ['draw-polygon'] // Correct button type for drawing polygons
            });
    
            // Initialize the drawing manager with the toolbar
            drawingManagerRef.current = new window.atlas.drawing.DrawingManager(mapRef.current, {
                toolbar: drawingToolbar,
                drawingInteractionType: window.atlas.drawing.DrawingInteractionType.click
            });
            console.log("Drawing Manager initialized", drawingManagerRef.current);
    
            if (drawingManagerRef.current.getSource().getShapes()) {
                mapRef.current.events.add('drawingcomplete', drawingManagerRef.current, handleDrawComplete);
                console.log("Drawing Manager events are available");
            } else {
                console.error("Drawing Manager events are not available");
            }
    
            // The toolbar is automatically added to the map by the DrawingManager
            console.log("Drawing Toolbar added to the DrawingManager");
        } else {
            console.error("Drawing tools script not loaded or atlas.drawing not available");
        }
    };
    
    
    const handleDrawComplete = (event) => {
        const shapes = drawingManagerRef.current.getSource().toJson().features;
        const polygons = shapes.filter(shape => shape.geometry.type === 'Polygon');
        console.log('is Delivery drawing?', isDelivery);
        if (polygons.length > 0) {
            const polygon = polygons[0];
            console.log('Polygon drawn:', polygon.geometry.coordinates[0]); // Log the drawn polygon
            console.log('Delivery Points:', deliveryPoints);
            console.log('Pickup Points:', pickupPoints);
            const selectedIds = getSelectedMarkerIds(polygon.geometry.coordinates[0]);
            console.log('Selected marker IDs:', selectedIds); // Log the selected marker IDs
            setSelectedMarkerIds(selectedIds);
            const driverId = prompt('Enter Driver ID:');
            if (driverId) {
                setDriverIdInput(driverId);
                updateDatabase(selectedIds, driverId);
            }
            drawingManagerRef.current.getSource().clear();
        }
    };
    
    
    
    const getSelectedMarkerIds = (polygonCoordinates) => {
        const selectedIds = [];
        const points = isDelivery ? pickupPoints : deliveryPoints;
        console.log('Is Delivery?', isDelivery);
        console.log('Polygon coordinates:', polygonCoordinates); // Log the polygon coordinates
        console.log('Points to check:', points); // Log the points being checked
    
        // Create a turf polygon
        const turfPolygon = turf.polygon([polygonCoordinates]);
    
        points.forEach(point => {
            const position = [point.longitude || point.lon, point.latitude || point.lat];
            console.log('Checking position:', position); // Log each position being checked
    
            // Create a turf point
            const turfPoint = turf.point(position);
    
            // Check if the point is inside the polygon
            if (turf.booleanPointInPolygon(turfPoint, turfPolygon)) {
                console.log('Point inside polygon:', point.id); // Log if a point is inside the polygon
                selectedIds.push(point.id);
            }
        });
    
        return selectedIds;
    };
    


    
    const updateDatabase = async (markerIds, driverId) => {
        try {
            const token = localStorage.getItem("token");
            const url = isDelivery ? `${process.env.REACT_APP_API_URL}/api/update_pickup_points` : `${process.env.REACT_APP_API_URL}/api/update_delivery_points`;
            console.log(`isDelivery: ${isDelivery}, URL: ${url}`); // Log the URL and isDelivery flag
            console.log('Payload:', { markerIds, driverId }); // Log the payload
            const response = await axios.post(url, { markerIds, driverId }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Server response:', response.data); // Log the server response
            console.log('Database updated successfully');
        } catch (error) {
            console.error('Error updating database:', error);
            console.log('Error details:', error.response ? error.response.data : 'No response data'); // Log error response from server
        }
    };
    

    useEffect(() => {
        if (mapRef.current) {
            console.log('Delivery points updated:', deliveryPoints);
            clearMarkers();
            updateMarkers();
            
        }
    }, [deliveryPoints, pickupPoints, drivers]);


    const clearMarkers = () => {
        if (mapRef.current) {
            mapRef.current.markers.clear();
        }
    };

    

    const updateMarkers = () => {
        if (!mapRef.current ) {
            console.error("Map is not initialized");
            return;
        }
        clearMarkers();    
        const points = isDelivery ? deliveryPoints : pickupPoints;

        const colorMap = {
            cancel: 'red',
            success: 'green',
            default: 'black'
        };

        drivers.forEach(driver => {
            console.log('Driver status:', driver)
            const color = colorMap[driver.status] || colorMap.default;
            const marker = new atlas.HtmlMarker({
                position: [driver.longitude, driver.latitude],
                htmlContent: `
                    <div style="position: relative; width: 30px; height: 30px;">
                        <div style="width: 25px; height: 25px; border-radius: 50%; background-color: purple; display: flex; align-items: center; justify-content: center; font-weight: bold; color: white;">
                            ${driver.user_id}
                        </div>
                    </div>
                `
            });
        
            mapRef.current.markers.add(marker);
            
            const defaultMarker = new atlas.HtmlMarker({
                position: [driver.home_longitude, driver.home_latitude],
                htmlContent: `
                    <div style="position: relative; width: 30px; height: 30px;">
                        <div style="width: 25px; height: 25px; background-color: blue; display: flex; align-items: center; justify-content: center; font-weight: bold; color: white;">
                            ${driver.user_id}
                        </div>
                    </div>
                `
            });
            mapRef.current.markers.add(defaultMarker);
        });
    
    
        points.forEach(point => {
            const color = colorMap[point.status] || colorMap.default;
            const marker = new atlas.HtmlMarker({
                position: [point.longitude || point.lon, point.latitude || point.lat],
                htmlContent: `
                    <div style="position: relative; width: 40px; height: 50px;"> <!-- Increase width to 40px -->
                        <div style="width: 35px; height: 35px; border-radius: 50%; background-color: green; display: flex; align-items: center; justify-content: center; font-weight: bold; color: white;"> <!-- Adjust width and font-size -->
                            ${point.id}-${point.sequence}
                        </div>
                    </div>
                `
            });
            
            mapRef.current.markers.add(marker);
        
        });
       
    };

    const updateSequence = async (pointId, sequence) => {
        try {
            const token = localStorage.getItem("token");
            const url = isDelivery ? `${process.env.REACT_APP_API_URL}/api/update_delivery_point_sequence` : `${process.env.REACT_APP_API_URL}/api/update_pickup_point_sequence`;
            const response = await axios.post(url, { pointId, sequence }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Server response:', response.data);
            console.log('Sequence updated successfully');
        } catch (error) {
            console.error('Error updating sequence:', error);
        }
    };
    

    const displayRoute = (route) => {
        if (!route || !route.legs || route.legs.length === 0) {
            console.error('Invalid route data');
            return;
        }
    
        // Clear existing markers
        clearMarkers();
    
        // Extract coordinates from the route data
        const coordinates = route.legs.flatMap(leg => leg.points.map(point => [point.longitude, point.latitude]));
    
        // Create a LineString for the route
        const line = new atlas.data.LineString(coordinates);
        const routeLine = new atlas.Shape({
            geometry: line,
            properties: {
                id: 'route'
            }
        });
    
        // Create a DataSource and add the route line to it
        const source = new atlas.source.DataSource();
        mapRef.current.sources.add(source);
        source.add(routeLine);
    
        // Create and add the LineLayer to the map
        const lineLayer = new atlas.layer.LineLayer(source, null, {
            strokeColor: 'blue',
            strokeWidth: 3
        });
        mapRef.current.layers.add(lineLayer);
    
        // Add markers for the start, stops, end, and delivery points
        const addMarker = (coord, label, color, borderColor, showLabel = true, size = 'default') => {
            const sizeStyles = size === 'small' ? 'width: 10px; height: 10px; padding: 2px;' : 'padding: 5px;';
            const marker = new atlas.HtmlMarker({
                position: coord,
                htmlContent: `
                    <div style="background-color: ${color}; border: 2px solid ${borderColor}; border-radius: 50%; ${sizeStyles} text-align: center;">
                        ${showLabel ? label : ''}
                    </div>
                `
            });
            mapRef.current.markers.add(marker);
        };
    
        // Add marker for start
        addMarker(coordinates[0], 'Start', 'green', 'darkgreen');
    
        // Add markers for stops (intermediate points)
        const stops = coordinates.slice(1, coordinates.length - 1);
        stops.forEach((coord, index) => {
            addMarker(coord, '', 'black', 'black', false, 'small');
        });
    
        // Add marker for end
        addMarker(coordinates[coordinates.length - 1], 'End', 'red', 'darkred');
    
        // Add markers for delivery points at the start of each leg (except for the first leg)
        route.legs.forEach((leg, index) => {
            if (index > 0) { // Skip the first leg
                const deliveryPointCoord = [leg.points[0].longitude, leg.points[0].latitude];
                addMarker(deliveryPointCoord, ` ${index}`, 'blue', 'darkblue');
            }
        });
    };
    
    
    const haversineDistance = (coord1, coord2) => {
        const toRad = (angle) => angle * (Math.PI / 180);
        
        const lat1 = coord1[0];
        const lon1 = coord1[1];
        const lat2 = coord2[0];
        const lon2 = coord2[1];
        
        const R = 6371; // Radius of the Earth in km
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + 
                  Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
                  Math.sin(dLon / 2) * Math.sin(dLon / 2);
        
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in km
    };
    
    // Nearest Neighbor Algorithm
    const nearestNeighbor = (start, points) => {
        const visited = [];
        let currentPoint = start;
        
        while (points.length > 0) {
            let nearestPointIndex = -1;
            let nearestDistance = Infinity;
            
            points.forEach((point, index) => {
                const distance = haversineDistance(currentPoint, point);
                if (distance < nearestDistance) {
                    nearestDistance = distance;
                    nearestPointIndex = index;
                }
            });
    
            visited.push(points[nearestPointIndex]);
            currentPoint = points[nearestPointIndex];
            points.splice(nearestPointIndex, 1); // Remove the visited point from the list
        }
    
        return visited;
    };
    
    const handleEntregaRoutes = async () => {
        if (drivers.length === 0) return;
    
        const start = [-12.00093305507352, -77.05374889286432];
        const driver_id = driverId;
        const driver = drivers.find(d => d.id === driver_id);
        const end = [driver.home_latitude, driver.home_longitude];
    
        const matchingPoints = deliveryPoints.filter(point => point.driver_id === driver.id);
        const stops = matchingPoints.map(point => [point.latitude, point.longitude]);
    
        console.log('Driver home address', driver.home_latitude, driver.home_longitude);
        console.log('Stops', stops);
    
        // Optimize stops order using Nearest Neighbor Algorithm
        const optimizedStops = nearestNeighbor(start, [...stops]);
        console.log('Optimized Stops:', optimizedStops);
    
        const route = await createRoute(start, end, optimizedStops);
        displayRoute(route);
    
        // Extract arrival times from each leg
        const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);
    
        // Map delivery points to optimized stops order and include arrival times
        const newSequence = optimizedStops.map((stop, index) => {
            const point = matchingPoints.find(mp => mp.latitude === stop[0] && mp.longitude === stop[1]);
            const arrivalTime = arrivalTimes[index];
            return point ? { id: point.id, arrival_time: arrivalTime } : null;
        }).filter(entry => entry !== null);
    
        console.log('New sequence:', newSequence);
    
        // Call the backend endpoint to update the sequence and arrival times in the database
        try {
            const token = localStorage.getItem("token");
            await axios.post(`${process.env.REACT_APP_API_URL}/api/update_sequences`, { sequences: newSequence }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Sequence and arrival times updated successfully');
        } catch (error) {
            console.error('Error updating sequence and arrival times:', error);
        }
    };
    
    
    
    const handlePickupRoutes = async () => {
        if (drivers.length === 0) return;
    
        const driver_id = driverId;
        const driver = drivers.find(d => d.id === driver_id);
    
        if (!driver) {
            console.error('Driver not found');
            return;
        }
    
        const start = [driver.home_latitude, driver.home_longitude]; // Driver's default location
        const end = [-12.00093305507352, -77.05374889286432]; // Specific end location
    
        const matchingPoints = pickupPoints.filter(point => point.driver_id === driver.id);
        const stops = matchingPoints.map(point => [point.latitude, point.longitude]);
    
        console.log('Driver home address', driver.home_latitude, driver.home_longitude);
        console.log('Stops', stops);
    
        // Optimize stops order using Nearest Neighbor Algorithm
        const optimizedStops = nearestNeighbor(start, [...stops]);
        console.log('Optimized Stops:', optimizedStops);
    
        const route = await createRoute(start, end, optimizedStops);
        displayRoute(route);
    
        // Extract arrival times from each leg
        const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);
    
        // Map pickup points to optimized stops order and include arrival times
        const newSequence = optimizedStops.map((stop, index) => {
            const point = matchingPoints.find(mp => mp.latitude === stop[0] && mp.longitude === stop[1]);
            const arrivalTime = arrivalTimes[index];
            return point ? { id: point.id, arrival_time: arrivalTime } : null;
        }).filter(entry => entry !== null);
    
        console.log('New sequence:', newSequence);
    
        // Call the backend endpoint to update the sequence and arrival times in the database
        try {
            const token = localStorage.getItem("token");
            await axios.post(`${process.env.REACT_APP_API_URL}/api/update_pickup_sequences`, { sequences: newSequence }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Sequence and arrival times updated successfully');
        } catch (error) {
            console.error('Error updating sequence and arrival times:', error);
        }
    };
    
    

    
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{ width: "100%", height: "100%" }}
          ref={mapContainerRef}
        ></div>
        <ArrowBackIcon onClick={onBackClick} style={{ color: 'black', position: 'absolute', top: "20px", left: "20px" }}/>
        <div style={{ position: "absolute", top: "10px", right: "10px", display: 'flex', flexDirection: 'column' }}>
          <IconButton color="primary" aria-label="Pick Up" onClick={handleRecojosClick}>
            <LocalShippingIcon />
          </IconButton>
          <IconButton color="primary" aria-label="Delivered" onClick={handleEntregasClick}>
            <CheckCircleIcon />
          </IconButton>
          {drivers.map((driver, index) => (
                <Button 
                    key={index} 
                    startIcon={<Avatar>{driver.name[0]}</Avatar>}
                    onClick={() => {handleDriverClick(driver.id); setDriverId(driver.id)}}
                >
                    {driver.name} {driver.id}
                </Button>
            ))}
            <IconButton color="primary" aria-label="Create Pickup Route" onClick={handlePickupRoutes}>
                <MapIcon />
            </IconButton>
            <IconButton color="primary" aria-label="Create Delivery Route" onClick={handleEntregaRoutes}>
                <CheckCircleIcon />
            </IconButton>
        </div>
        
      </div>
    );
};


export default RoutesAdmin;

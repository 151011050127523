import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, Button, ButtonBase, Alert } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import MyLocationIcon from '@mui/icons-material/MyLocation';

function MapSelectionDriver({onOptionClick, onBackClick, userId}) {
  const mapContainerRef = useRef(null);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [address, setAddress] = useState('');
  const [district, setDistrict] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [userLocation, setUserLocation] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isMapInitialized, setIsMapInitialized] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('');
  
  const atlas = window.atlas;
  const mapRef = useRef(null);
  
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
  };

  const handleSubmit = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, { params: { query: district + ', Lima', single_result: true } });
    // Assume the response contains the lat and lon for the district
    setLat(response.data.lat);
    setLon(response.data.lon);
    setIsAddressSelected(true);
  };

  const handleConfirmClick = async () => {
    await handleSubmitAddress();
    onBackClick();
  }

  const handleSubmitAddress = async () => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/` + userId, {
      address: address,
      default_latitude: lat,
      default_longitude: lon
    });
  }

  const handleSearchChange = async (event, value) => {
    setSearchQuery(value);
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, { params: { query: value + ', Lima' } });
    setSuggestions(response.data.results);
  };
  
  const handleSuggestionSelected = (event, value) => {
    
    if (value) {
      const { lat, lon } = value.position;
      setLat(lat);
      setLon(lon);
      
      mapRef.current.setCamera({
        center: [lon, lat],
        zoom: 17
      });
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({ lat: position.coords.latitude, lon: position.coords.longitude });
      });
    }
  }, []);

  useEffect(() => {
    if (lat && lon && !isMapInitialized && mapContainerRef.current) {
      const atlas = window.atlas;
      const map = new atlas.Map(mapContainerRef.current, {
        center: [lon, lat],
        zoom: 14,
        authOptions: {
          authType: 'subscriptionKey',
          subscriptionKey: process.env.REACT_APP_MAPBOX_SUBSCRIPTION_KEY
        }
      });
      const markerElement = document.querySelector('.marker');
      map.events.add('move', () => {
        markerElement.classList.add('moving');
        console.log(`Moving: lat=${lat}, lon=${lon}`);
      });
  
      map.events.add('moveend', () => {
        markerElement.classList.remove('moving');
        const camera = map.getCamera();
        setLat(camera.center[1]);
        setLon(camera.center[0]);
        console.log(`Move ended: lat=${camera.center[1]}, lon=${camera.center[0]}`);
      });

      mapRef.current = map;
      setIsMapInitialized(true);
    }
  }, [lat, lon, isMapInitialized]);

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLat(latitude);
        setLon(longitude);
        console.log(`Moving: lat=${lat}, lon=${lon}`)
        mapRef.current.setCamera({
          center: [longitude, latitude],
          zoom: 17
        });
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const handleBack = () => {
    setIsAddressSelected(false);
    setIsMapInitialized(false);
  };

  return (
    <div>
      {!isAddressSelected && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
            <ButtonBase onClick={() => { onBackClick(); onOptionClick('back'); }} style={{ padding: '20px' }}>
              <ArrowBackIcon />
            </ButtonBase>
            <span style={{ marginLeft: 'auto', marginRight: 'auto' }}>Pick up location</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              value={address}
              onChange={handleAddressChange}
              placeholder="Address"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              value={district}
              onChange={handleDistrictChange}
              placeholder="District"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MapIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={handleSubmit}>Confirm</Button>
          </div>
        </>
      )}
      {isAddressSelected && (
        <>
          <div style={{ position: 'relative', width: '100%', height: '60vh' }}>
          <ButtonBase 
            onClick={handleBack} 
            style={{ 
              position: 'absolute', 
              top: 20, 
              left: 20, 
              zIndex: 1, 
              color: 'black', 
              backgroundColor: 'white', 
              borderRadius: '50%', 
              padding: 10
            }}
          >
            <ArrowBackIcon />
          </ButtonBase>
            <Autocomplete
              options={suggestions}
              getOptionLabel={(option) => option.address.freeformAddress}
              onInputChange={handleSearchChange}
              onChange={handleSuggestionSelected}
              renderInput={(params) => <TextField 
                {...params} 
                label="Search" 
                variant="outlined" 
                InputProps={{
                  ...params.InputProps,
                  style: { 
                    ...params.InputProps.style,
                    height: '40px', // Adjust the height as needed
                    padding: '0 30px 0 8px', // Adjust the padding as needed
                    
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />}
              style={{ position: 'absolute', top: 20, left: 90, zIndex: 1, width: '75%', borderRadius: '20px', backgroundColor: 'white' }}
            />
            <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }}></div>
            <div className="marker">
              <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 0 24 24" width="60px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
              </svg>
            </div>
            <ButtonBase 
              onClick={handleLocationClick} 
              style={{ position: 'absolute', height: '65px', width: '65px', bottom: 50, left: '50%', transform: 'translateX(-50%)', zIndex: 1, color: 'black', backgroundColor: 'white', borderRadius: '50%', }}
            >
              <MyLocationIcon style={{ fontSize: 45 }}/>
            </ButtonBase>
          </div>
          <div style={{ height: '20vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Alert severity="info">
              Move the map to position the marker exactly at your pick-up location.
            </Alert>
          </div>
          
          <div style={{ height: '10vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="contained" onClick={handleConfirmClick} style={{ width: '50%' }}>Confirm</Button>
          </div>
        </>
      )}
    </div>
  );
}

export default MapSelectionDriver;



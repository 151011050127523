import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Carousel from 'react-material-ui-carousel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { DownloadExcelResumen } from './DownloadExcelResumen';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');

function ReportList({ userId, setActiveComponent}) {
    const [orders, setOrders] = useState([]);
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);
    const [isDeliveryClicked, setIsDeliveryClicked] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}`)
          .then(response => {
            setDeliveryPoints(response.data.delivery_points || []);
          })
          .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handlePointClick = (dp) => {
        setSelectedDeliveryPoint(dp);
        setIsDeliveryClicked(true);

    }

    const handleBackClick = () => {
        if (isDeliveryClicked) {
            setIsDeliveryClicked(false);
            setActiveComponent('ReportList');
        } else {
            setActiveComponent('Map');
        }
    };

    

    const currentDate = dayjs().format('DD-MM-YYYY');
    const dayOnly = dayjs().format('DD');
    const currentDay = dayjs().format('dddd').charAt(0).toUpperCase() + dayjs().format('dddd').slice(1);
    const currentDayDeliveryPoints = deliveryPoints.filter(dp => dayjs(dp.delivery_date).format('DD-MM-YYYY') === currentDate);
    const allDelivered = currentDayDeliveryPoints.every(dp => 
      ['entregado', 'cancelado', 'pospuesto'].includes(dp.status)
    );
    const totalDeliveries = currentDayDeliveryPoints.length;
    const totalCost = totalDeliveries === 1 ? 15 : totalDeliveries * 9;
    const totalCharged = currentDayDeliveryPoints.reduce((sum, dp) => sum + (dp.charged || 0), 0);
    const amountToDeposit = totalCharged - totalCost;
    const amountToPay = totalCost - totalCharged;

    const handleDownloadResumenClick = () => {
      DownloadExcelResumen(deliveryPoints);
    };

    return (
      <div>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        {isDeliveryClicked ? (
          <div>
            <div style={{ height: "50vh", overflow: "hidden" }}>
              <Carousel>
                {selectedDeliveryPoint.image_urls &&
                selectedDeliveryPoint.image_urls.length > 0 ? (
                  selectedDeliveryPoint.image_urls.map((url, index) => (
                    <div key={index}>
                      <img
                        src={url}
                        alt={`Image ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "50vh",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/llamaFeliz.webp`}
                      alt="Default"
                      style={{
                        width: "100%",
                        height: "50vh",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </Carousel>
            </div>
            <div
              style={{
                height: "40vh",
                padding: "20px",
                backgroundColor: "#f7f7f7",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  color: "#000",
                  fontSize: "25px",
                }}
              >
                <p>
                  <strong>Nombre:</strong>{" "}
                  {selectedDeliveryPoint.recipient_name}
                </p>
                <p>
                  <strong>Dirección:</strong> {selectedDeliveryPoint.address}
                </p>
                <p>
                  <strong>Teléfono:</strong>{" "}
                  {selectedDeliveryPoint.recipient_phone}
                </p>
                <p>
                  <strong>Notas:</strong> {selectedDeliveryPoint.notes}
                </p>
                <p>
                  <strong>Distrito:</strong> {selectedDeliveryPoint.distric}
                </p>
                <p>
                    <strong>Estado:</strong> 
                    {selectedDeliveryPoint.status === 'encamino' ? ' En camino' : 
                    selectedDeliveryPoint.status === 'entregado' ? ' Entregado' : 
                    selectedDeliveryPoint.status}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <h1 style={{ color: "black" }}>
              {currentDay} {dayOnly}
            </h1>
            <IconButton
        style={{
          position: "absolute",
          bottom: "88%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(0.7)",
        }}
        onClick={handleDownloadResumenClick}
      >
        <FileCopyIcon />
      </IconButton>
            <div style={{
                padding: "20px",
                backgroundColor: "#f7f7f7",
                overflowY: "auto",
              }}>
            {allDelivered && (
                        <div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            color: "#000",
                            fontSize: "25px",
                          }}>
                            <p>Entregas: {totalDeliveries} - Costo total: {totalCost}</p>
                            {totalCharged > 0 && (
                                <>
                                    <p>Fast Max cobro: {totalCharged.toFixed(2)}</p>
                                    {totalCharged >= totalCost ? (
                                        <p>Total a depositar: {(totalCharged - totalCost).toFixed(2)}</p>
                                    ) : (
                                        <p>Total a pagar: {(totalCost - totalCharged).toFixed(2)}</p>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    </div>
            <h2 style={{ color: "black" }}>Entregas</h2>
            <div style={{ maxHeight: "250px", overflowY: "auto" }}>
              <List>
                {currentDayDeliveryPoints.map((dp) => (
                  <ListItem
                    key={dp.id}
                    button
                    onClick={() => handlePointClick(dp)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          backgroundColor:
                            dp.status === "entregado"
                              ? "#66bb6a" // Green
                              : dp.status === "encamino"
                              ? "#42a5f5" // Blue
                              : "#ef5350", // Red
                        }}
                      >
                        <LocationOnIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <span
                          style={{ color: "black" }}
                        >{`${dp.recipient_name.substring(0, 15)} - ID: ${dp.id}${dp.charge ? ` - Se Cobro S/. ${dp.charged}` : ''}`}</span>
                      }
                      secondary={
                        `${dp.notes_driver ? dp.notes_driver.substring(0, 15) : ''} - 
                         ${dp.address ? dp.address.substring(0, 15) : ''}`
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </>
        )}
      </div>
    );


};

export default ReportList;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, List, ListItem, ListItemText, IconButton, Avatar, ListItemAvatar } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Carousel from 'react-material-ui-carousel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { DownloadExcelResumen } from './DownloadExcelResumen';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.locale('es');

function DeliveryReport({ userId, setActiveComponent, onBackClick }) {
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);
    const [isDeliveryClicked, setIsDeliveryClicked] = useState(false);

    useEffect(() => {
        if (selectedDate) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}/deliveries`, {
                params: { date: selectedDate.format('YYYY-MM-DD') }
            })
            .then(response => {
                setDeliveryPoints(response.data.delivery_points || []);
            })
            .catch(error => console.error('Error fetching data:', error));
        }
    }, [selectedDate, userId]);

    const handlePointClick = (dp) => {
        setSelectedDeliveryPoint(dp);
        setIsDeliveryClicked(true);
    };

    const handleBackClick = () => {
        if (isDeliveryClicked) {
            setIsDeliveryClicked(false);
        } else {
            onBackClick();
        }
    };

    const handleDownloadResumenClick = () => {
        DownloadExcelResumen(deliveryPoints);
      };

    const currentDayDeliveryPoints = deliveryPoints.filter(dp => dayjs(dp.delivery_date).format('YYYY-MM-DD') === selectedDate?.format('YYYY-MM-DD'));
    const allDelivered = currentDayDeliveryPoints.every(dp => 
        ['entregado', 'cancelado', 'pospuesto'].includes(dp.status)
    );
    const totalDeliveries = currentDayDeliveryPoints.length;
    const totalCost = totalDeliveries === 1 ? 15 : totalDeliveries * 9;
    const totalCharged = currentDayDeliveryPoints.reduce((sum, dp) => sum + (dp.charged || 0), 0);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: { xs: '10px', sm: '20px' } }}>
                <IconButton onClick={handleBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <DatePicker
                        value={selectedDate}
                        onChange={(newDate) => setSelectedDate(newDate)}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                            <Box ref={inputRef} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}>
                                <input {...inputProps} readOnly style={{ border: 'none', background: 'transparent', textAlign: 'center', width: '100%' }} />
                                {InputProps?.endAdornment}
                            </Box>
                        )}
                    />
                </Box>
            </Box>
            <IconButton
                style={{
                position: "absolute",
                bottom: "88%",
                right: 30,
                zIndex: 1000,
                backgroundColor: "white",
                border: "2px solid black",
                borderRadius: "50%",
                color: "black",
                marginBottom: "10px",
                transform: "scale(0.7)",
                }}
                onClick={handleDownloadResumenClick}
            >
                <FileCopyIcon />
            </IconButton>
            {isDeliveryClicked ? (
                <Box sx={{ height: '100vh', overflowY: 'auto' }}>
                    <Box sx={{ height: "50vh", overflow: "hidden" }}>
                    <Carousel>
                    {selectedDeliveryPoint.image_urls && selectedDeliveryPoint.image_urls.length > 0 ? (
                        selectedDeliveryPoint.image_urls.map((url, index) => (
                        <Box 
                            key={index} 
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}
                        >
                            <img
                            src={url}
                            alt={`Image ${index + 1}`}
                            style={{ width: "50%", height: "auto", objectFit: "cover" }}
                            />
                        </Box>
                        ))
                    ) : (
                        <Box 
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}
                        >
                        <img
                            src={`${process.env.PUBLIC_URL}/llamaFeliz.webp`}
                            alt="Default"
                            style={{ width: "50%", height: "auto", objectFit: "cover" }}
                        />
                        </Box>
                    )}
                    </Carousel>
                    </Box>
                    <Box sx={{ height: "40vh", padding: "20px", backgroundColor: "#f7f7f7", overflowY: "auto" }}>
                        <Box sx={{ padding: "20px", backgroundColor: "white", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", color: "#000", fontSize: { xs: "18px", sm: "25px" } }}>
                            <p>
                                <strong>Nombre:</strong> {selectedDeliveryPoint.recipient_name ? selectedDeliveryPoint.recipient_name.substring(0, 15) : 'No name available'}
                            </p>
                            <p>
                                <strong>Dirección:</strong> {selectedDeliveryPoint.address ? selectedDeliveryPoint.address.substring(0, 15) : 'No address available'}
                            </p>
                            <p><strong>Teléfono:</strong> {selectedDeliveryPoint.recipient_phone}</p>
                            <p><strong>Cobrar:</strong> {selectedDeliveryPoint.charge}</p>
                            <p><strong>Cobrado:</strong> {selectedDeliveryPoint.charged}</p>
                            <p><strong>Notas:</strong> {selectedDeliveryPoint.notes}</p>
                            <p><strong>Distrito:</strong> {selectedDeliveryPoint.distric}</p>
                            <p><strong>Estado:</strong> {selectedDeliveryPoint.status === 'encamino' ? 'En camino' : selectedDeliveryPoint.status === 'entregado' ? 'Entregado' : selectedDeliveryPoint.status}</p>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <>
                    {selectedDate && (
                        <>
                            <Box sx={{ textAlign: 'center', padding: { xs: '10px', sm: '14px' }, backgroundColor: "#f7f7f7" }}>
                                <h1 style={{ color: "black", fontSize: { xs: '12px', sm: '14px' } }}>{selectedDate.format('dddd DD-MM')}</h1>
                                {allDelivered && (
                                    <Box sx={{ padding: "20px", backgroundColor: "white", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", color: "#000", fontSize: { xs: "18px", sm: "25px" }, marginBottom: '20px' }}>
                                        <p>Entregas: {totalDeliveries} - Costo total: {totalCost}</p>
                                        {totalCharged > 0 && (
                                            <>
                                                <p>Fast Max cobro: {totalCharged.toFixed(2)}</p>
                                                {totalCharged >= totalCost ? (
                                                    <p>Total a depositar: {(totalCharged - totalCost).toFixed(2)}</p>
                                                ) : (
                                                    <p>Total a pagar: {(totalCost - totalCharged).toFixed(2)}</p>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                )}
                            </Box>
                            <h2 style={{ color: "black", textAlign: 'center' }}>Entregas</h2>
                            <Box sx={{ maxHeight: "400px", overflowY: "auto", padding: { xs: '10px', sm: '20px' } }}>
                                <List>
                                    {currentDayDeliveryPoints.map((dp) => (
                                        <ListItem key={dp.id} button onClick={() => handlePointClick(dp)}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ backgroundColor: dp.status === "entregado" ? "#66bb6a" : dp.status === "encamino" ? "#42a5f5" : "#ef5350" }}>
                                                    <LocationOnIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<span style={{ color: "black", fontSize: { xs: '14px', sm: '16px' } }}>{`${dp.recipient_name.substring(0, 15)} - ID: ${dp.id}`}</span>}
                                                
                                            />
                                            
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </>
                    )}
                </>
            )}
        </LocalizationProvider>
    );
}

export default DeliveryReport;
import React from 'react';

const Modal = ({ message, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-message">
          {message}
        </div>
        <button className="modal-close-button" onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
};

export default Modal;
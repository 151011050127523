import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Select, MenuItem, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { esES } from '@mui/x-date-pickers/locales';

function Recojo({ onBackClick, onEntregaSuccess }) {
  const [pickupDate, setPickupDate] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  
  const ConfirmModal = ({ message, onConfirm, onCancel }) => (
    <div className="modal-overlay-entrega">
        <div className="modal-content">
            <div className="modal-message">{message}</div>
            <div className="modal-actions">
                <button className="modal-ok-button" onClick={onConfirm}>OK</button>
                <button className="modal-cancel-button" onClick={onCancel}>Cancel</button>
            </div>
        </div>
    </div>
);

useEffect(() => {
  // Fetch the user's saved addresses
  const fetchAddresses = async () => {
    const userId = localStorage.getItem('user_id');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user_addresses/${userId}`);
      const nonArchivedAddresses = response.data.addresses.filter(address => !address.archived);
      setAddresses(nonArchivedAddresses);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  fetchAddresses();
}, []);

  const handleConfirmClick = () => {
    if (!pickupDate) {
        setErrorMessage('Todos los campos obligatorios deben completarse.');
        return;
    }

    setErrorMessage('');
    setIsModalOpen(true);
};



const handleSubmit = async () => {
    setIsModalOpen(false);
    const token = localStorage.getItem('token'); 

    if (!token) {
        console.error("No token available.");
        return;
    }

    try {
        // Format pickupDate to 'YYYY-MM-DD' string
        const formattedPickupDate = pickupDate ? pickupDate.format('YYYY-MM-DD') : null;

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/recojo`, {
            user_id: localStorage.getItem('user_id'),
            pickup_date: formattedPickupDate,  // Send only the date part
            notes: additionalNotes,
            address_id: selectedAddress
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log("Response from server:", response.data);
        onEntregaSuccess();
    } catch (error) {
        console.error('Error creating recojo:', error.response?.data || error.message);
    }
  };
    const handleCancel = () => {
      setIsModalOpen(false);
  };

  const handleDeleteClick = async (addressId) => {
    const token = localStorage.getItem('token');
    
    // Show a confirmation dialog in Spanish
    const confirmation = window.confirm("¿Estás seguro de que deseas eliminar esta dirección?");
    
    if (!confirmation) {
        // If the user clicks "Cancel", do nothing
        return;
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/archive_address/${addressId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Refresh the list of addresses
      const updatedAddresses = addresses.filter(address => address.id !== addressId);
      setAddresses(updatedAddresses);
      alert('La dirección ha sido eliminada correctamente.');
      
    } catch (error) {
      console.error('Error al archivar la dirección:', error.response?.data || error.message);
      alert('Hubo un error al intentar eliminar la dirección.');
    }
  };

  
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="es"
      localeText={
        esES.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <div className="recojo-container">
        <div className="back-arrow">
          <ArrowBackIcon onClick={onBackClick} style={{ color: "black" }} />
        </div>
        
        <h1 style={{ color: "black" }}>Selecciona fecha de recojo</h1>
        <p style={{ color: "black" }}>
          Reserva hasta las 08:00 am del día de entrega
        </p>
        {/* Dropdown to select address */}
        <Select
          value={selectedAddress}
          onChange={(e) => setSelectedAddress(e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>Selecciona una dirección</MenuItem>
          {addresses.map((address) => (
            <MenuItem key={address.id} value={address.id}>
              {address.address}
              {/* Add delete icon */}
              <IconButton edge="end" onClick={() => handleDeleteClick(address.id)}>
                <DeleteIcon />
              </IconButton>
            </MenuItem>
          ))}
        </Select>
        <DatePicker
          label="Fecha de Recojo"
          inputFormat="DD/MM/YYYY"
          value={pickupDate}
          onChange={(newValue) => setPickupDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <TextField
          label="Notas Adicionales"
          multiline
          rows={4}
          value={additionalNotes}
          onChange={(e) => setAdditionalNotes(e.target.value)}
          margin="normal"
        />
        <p style={{ color: "black" }}>
          El currier te visitara entre las 09:00-12:00 (hora local en
          Lima)
        </p>

        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Button
          variant="contained"
          onClick={handleConfirmClick}
          style={{ backgroundColor: "#000", color: "#fff", width: "100%" }}
        >
          Confirmar
        </Button>
        {isModalOpen && (
          <ConfirmModal
            message="¿Está seguro de que la información ingresada es correcta?"
            onConfirm={handleSubmit}
            onCancel={handleCancel}
          />
        )}
      </div>
    </LocalizationProvider>
  );
}

export default Recojo;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, AlertTitle } from '@mui/material';
import dayjs from 'dayjs';

function MenuUser({ user, setActiveComponent, deliveryPoints, orders }) {
    const currentDate = dayjs().format('YYYY-MM-DD');
    

    const statusOrder = {
        pending: 'Agendado',
        encamino: 'En camino',
        recogido: 'recogido',
    };

    const statusDeliveryPoint = {
        pending: 'Pendiente',
        encamino: 'En camino',
        entregado: 'Entregado'
    };

    const currentDayDeliveryPoints = deliveryPoints.filter(point => 
        dayjs(point.delivery_date).format('YYYY-MM-DD') === currentDate
    );

    const allDeliveryPointsDeliveredOrOnway = currentDayDeliveryPoints.every(point => 
        ['encamino', 'entregado', 'cancelado'].includes(point.status)
    );

    const allDelivered = currentDayDeliveryPoints.every(point => 
        point.status === 'entregado'
    );

    let deliveryStatus = null;
    if (currentDayDeliveryPoints.length > 0) {
        if (allDelivered) {
            deliveryStatus = 'entregado';
        } else if (allDeliveryPointsDeliveredOrOnway) {
            deliveryStatus = 'encamino';
        }
    };
    const  handleServiceClick = () => {
        setActiveComponent('PendingList');
    };

    const handleReportClick = () => {
        setActiveComponent('ReportList');
    };

    return (
        <div style={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            color: "#000",
        }}>
            <h2>¡Hola {user.username}!</h2>
            {deliveryStatus ? (
                <Alert severity="info" style={{ marginTop: "20px", cursor: "pointer" }} onClick={handleReportClick}>
                    <AlertTitle>Servicio: {statusDeliveryPoint[deliveryStatus]}</AlertTitle>
                </Alert>
            ) : (
                orders.length === 0 ? (
                    <Alert severity="info" style={{ marginTop: "20px", cursor: "pointer" }} onClick={() => setActiveComponent('Recojo')}>
                        <AlertTitle>Agenda un servicio.</AlertTitle>
                    </Alert>
                ) : (
                    orders.map(order => {
                        // Extract only the month and day from the recojo_date
                        const recojoDate = new Date(order.recojo_date);
                        const formattedRecojoDate = recojoDate.toLocaleDateString('es-ES', { month: 'long', day: 'numeric' });
                        
                        return (
                            <Alert severity="info" style={{ marginTop: "20px", cursor: "pointer" }} onClick={handleServiceClick} key={order.id}>
                                <AlertTitle>Recojo ID: {order.id} {statusOrder[order.status]} {formattedRecojoDate}</AlertTitle>
                                <p>{order.driver_notes}</p>
                            </Alert>
                        );
                    })
                )
            )}
        </div>
    );
}

export default MenuUser;
import React, { useEffect, useState } from "react";
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Typography, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const Location = ({ onBackClick }) => {
    const [deliveryPoints, setDeliveryPoints] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/admin`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then((response) => {
                setDeliveryPoints(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleWhatsappClick = (phone) => {
        const message = encodeURIComponent("Por favor envíe su ubicación lo antes posible.");  // Customize the message
        window.open(`https://wa.me/+51${phone}?text=${message}`, '_blank');
    };

    const handleWhatsappUserClick = (phone, recipientName) => {
        const message = encodeURIComponent(`El cliente ${recipientName} aún no ha enviado la ubicación para la entrega, por favor contáctelo para obtener la ubicación.`);
        window.open(`https://wa.me/+51${phone}?text=${message}`, '_blank');
    };

    return (
        <Box p={2}>
            {/* Back Arrow */}
            <IconButton onClick={onBackClick} style={{ marginBottom: '20px' }}>
                <ArrowBackIcon />
            </IconButton>
            {/* Title */}
            <Typography variant="h6" gutterBottom style={{ color: 'black' }}>
                Ubicaciones Faltantes
            </Typography>
            {/* Table to display delivery points */}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Delivery ID</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Dirección</TableCell>
                        <TableCell>Usuario</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deliveryPoints
                        .filter(dp => !dp.latitude || !dp.longitude)  // Only show items without latitude and longitude
                        .map((deliveryPoint) => (
                            <TableRow key={deliveryPoint.id}>
                                <TableCell>{deliveryPoint.id}</TableCell>
                                <TableCell>{deliveryPoint.recipient_name}</TableCell>
                                <TableCell>
                                    {deliveryPoint.recipient_phone}
                                    <IconButton onClick={() => handleWhatsappClick(deliveryPoint.recipient_phone)}>
                                        <WhatsAppIcon style={{ color: 'green' }} />
                                    </IconButton>
                                </TableCell>
                                <TableCell>{deliveryPoint.address}</TableCell>
                                <TableCell>
                                    {deliveryPoint.user_name}
                                    <IconButton onClick={() => handleWhatsappUserClick(deliveryPoint.user_phone, deliveryPoint.recipient_name)}>
                                        <WhatsAppIcon style={{ color: 'green' }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default Location;

import React, { useState, useEffect } from "react";
import axios from 'axios';
import Map from "./Map";
import Recojo from "./Recojo";
import Entrega from "./Entrega";
import EditDeliveryPoint from "./EditDeliveryPoint";
import EditOrder from "./EditOrder";
import Options from "./Options";
import MyAccount from "./MyAccount";
import { set } from "date-fns";
import MapSelection from "./MapSelection";
import PendingList from "./PendingList";
import ReportList from "./ReportList";  
import DeliveryReport from "./DeliveryReport";  
import PrintDeliveries from "./PrintDeliveries";
import Masivo from "./Masivo";


function MainUser({ user_id, username, onLogout, email, phone_number, onUpdateUser }) {    
    const [activeComponent, setActiveComponent] = useState('Map');
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isUpdated, setIsUpdated] = useState(true);
    const [addresses, setAddresses] = useState([]);
    
    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                const token = localStorage.getItem('token'); // Fetch token from local storage (if using token-based auth)
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user_addresses/${user_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include authorization header if necessary
                    }
                });
                
                if (response.data.addresses.length === 0) {
                    // If no addresses are found, navigate to 'MapSelection'
                    setActiveComponent('MapSelection');
                } else {
                    // If addresses are found, update the state
                    setAddresses(response.data.addresses);
                }
            } catch (error) {
                console.error('Error fetching user addresses:', error);
            }
        };
    
        // Call the API only if addresses are empty
        if (addresses && addresses.length === 0) {
            fetchAddresses(); // Fetch addresses from the API
        }
    }, [addresses, user_id]); // Add user_id as a dependency

    const handleReservaClick = () => {
        console.log('reservar');
        setActiveComponent('Recojo');
    };
    

    const handleEntregaClick = () => {
        console.log('entrega');
        setActiveComponent('Entrega');
    };

    const handleEntregaSuccess = () => {
        setActiveComponent('Map');
        setIsUpdated(true);
    }

    const handleBackClick = () => {
        setActiveComponent('Map');
        setIsUpdated(true); 
    };

    const handleMapSelectionClick = () => {
        setActiveComponent('MapSelection');
    };

    const handleEditClick = (dp) => {
        setSelectedDeliveryPoint(dp);
        setActiveComponent('EditDeliveryPoint');
    };
    const handleEditOrderClick = (order) => {
        setSelectedOrder(order);
        setActiveComponent('EditOrder');
    };

    const handleEditSubmit = async (updatedDeliveryPoint) => {
        const token = localStorage.getItem('token');
        // TODO: Update the delivery point on the server
        // This is just a placeholder. Replace it with your actual code.
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/delivery_points/${updatedDeliveryPoint.id}`, updatedDeliveryPoint, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            // If the update was successful, navigate back to the previous screen
            handleBackClick();
        } else {
            // If the update failed, handle the error
            console.error('Failed to update delivery point');
        }
    };

    const handleEditOrderSubmit = async (updatedOrder) => {
        const token = localStorage.getItem('token');
        // Update the order on the server
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/${updatedOrder.id}`, updatedOrder, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    
        if (response.status === 200) {
            // If the update was successful, navigate back to the previous screen
            handleBackClick();
        } else {
            // If the update failed, handle the error
            console.error('Failed to update order');
        }
    };
    const handleMenuClick = () => {
        setActiveComponent('Options');
    };

    const handleOptionClick = (option) => {
        if (option === 'back') {
            setActiveComponent('Map');
        } else if (option === 'MapSelection') {
            setActiveComponent('MapSelection');
        } else if (option === 'Print') {
            setActiveComponent('Print');
        } else if (option === 'MyDeliveries') {
            setActiveComponent('MyDeliveries');
        } else {
            setActiveComponent('MyAccount')
            // Handle other options
            
        }
    };
      
    return (
        <div className="container">
            {['Map'].includes(activeComponent) && (
                <>
                    <div className="map-container">
                        <Map handleMenuClick={handleMenuClick} userId={user_id} handleEntregaClick={handleEntregaClick} handleRecojoClick={handleReservaClick} handleMapSelection={handleMapSelectionClick} onEntregaSuccess={handleEntregaSuccess} setActiveComponent={setActiveComponent}/>
                    </div>
                </>
            )}
            {activeComponent === 'ReportList' && <ReportList userId={user_id} onBackClick={handleBackClick} setActiveComponent={setActiveComponent} />}
            {activeComponent === 'PendingList' && <PendingList userId={user_id} onEditClick={handleEditClick} onBackClick={handleBackClick}/>}
            {activeComponent === 'Recojo' && <Recojo onBackClick={handleBackClick} setIsUpdated={setIsUpdated} onEntregaSuccess={handleEntregaSuccess} />}
            {activeComponent === 'Entrega' && <Entrega onBackClick={handleBackClick} onEntregaSuccess={handleEntregaSuccess} setIsUpdated={setIsUpdated} />}
            {activeComponent === 'EditDeliveryPoint' && selectedDeliveryPoint && (
                <EditDeliveryPoint 
                    onBackClick={handleBackClick}
                    deliveryPoint={selectedDeliveryPoint}
                    onSubmit={handleEditSubmit}
                    setIsUpdated={setIsUpdated}    
                />
            )}
            {activeComponent === 'EditOrder' && selectedOrder && (
                <EditOrder 
                    onBackClick={handleBackClick}
                    order={selectedOrder}
                    onSubmit={handleEditOrderSubmit}
                    setIsUpdated={setIsUpdated}    
                />
            )}
            {activeComponent === 'Options' && <Options username={username} onOptionClick={handleOptionClick} onBackClick={handleBackClick} handleMapSelection={handleMapSelectionClick} setActiveComponent={setActiveComponent} onLogout={onLogout}/>}
            {activeComponent === 'MyAccount' && <MyAccount onUpdateUser={onUpdateUser} userId={user_id} username={username} phoneNumber={phone_number} email={email} onOptionClick={handleOptionClick} onBackClick={handleBackClick} onLogout={onLogout} />}
            {activeComponent === 'MyDeliveries' && <DeliveryReport userId={user_id} onBackClick={handleBackClick}/>}
            {activeComponent === 'MapSelection' && <MapSelection onOptionClick={handleOptionClick} onBackClick={handleBackClick} userId={user_id} />}
            {activeComponent === 'Print' && <PrintDeliveries userId={user_id} onBackClick={handleBackClick} />}
            {activeComponent === 'Masivo' && <Masivo userId={user_id} onBackClick={handleBackClick} />} 
        </div>
    );
}

export default MainUser;
import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';

const PrintDeliveries = ({ userId, onBackClick }) => {
    const [selectedDeliveryPoints, setSelectedDeliveryPoints] = useState([]);
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const printRef = React.useRef();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}`)
            .then(response => {
                setDeliveryPoints(response.data.delivery_points || []);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [userId]);

    const handleSelectionChange = (dp) => {
        setSelectedDeliveryPoints(prev => {
            if (prev.some(item => item.id === dp.id)) {
                return prev.filter(item => item.id !== dp.id);
            } else {
                return [...prev, dp];
            }
        });
    };

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: { xs: 2, sm: 4 },
                color: 'black',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                borderRadius: 0, // Full screen, no border radius
            }}
        >
            <Box sx={{ mb: 2 }}>
                <ArrowBackIcon onClick={onBackClick} style={{ color: 'black', cursor: 'pointer' }} />
            </Box>
            <h2 style={{ fontSize: '1.5rem', textAlign: 'center' }}>Escoge tus Entregas a imprimir</h2>
            <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {deliveryPoints.map(dp => (
                    <ListItem key={dp.id}>
                        <Checkbox
                            checked={selectedDeliveryPoints.some(item => item.id === dp.id)}
                            onChange={() => handleSelectionChange(dp)}
                        />
                        <ListItemAvatar>
                            <Avatar>
                                <LocationOnIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${dp.recipient_name} - ID: ${dp.id}`}
                            secondary={`${dp.address} - ${dp.distric}`}
                            primaryTypographyProps={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
                            secondaryTypographyProps={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
                        />
                    </ListItem>
                ))}
            </List>
            <Button
                variant="contained"
                onClick={handlePrint}
                startIcon={<PrintIcon />}
                sx={{
                    width: '100%',
                    mt: 2,
                    fontSize: { xs: '0.9rem', sm: '1rem' },
                    padding: { xs: '8px 16px', sm: '10px 20px' },
                }}
            >
                Imprimir
            </Button>
            <div style={{ display: 'none' }}>
                <div ref={printRef}>
                    {selectedDeliveryPoints.map(dp => (
                        <div
                            key={dp.id}
                            style={{
                                width: '10cm',
                                height: '5cm',
                                border: '1px solid black',
                                marginBottom: '10px',
                                padding: '5px',
                                boxSizing: 'border-box',
                                position: 'relative',
                            }}
                        >
                            <p style={{ color: 'black', fontSize: '36px' }}>
                                <strong>ID:</strong> {dp.id}
                            </p>
                            <p style={{ color: 'black', fontSize: '22px' }}>{dp.recipient_name}</p>
                            <p style={{ color: 'black', fontSize: '22px' }}>{dp.distric}</p>
                            <p style={{ color: 'black', fontSize: '22px' }}>{dp.address}</p>
                            <img
                                src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
                                alt="Logo"
                                style={{ width: '2cm', height: 'auto', position: 'absolute', bottom: '5px', right: '5px' }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Box>
    );
};

export default PrintDeliveries;
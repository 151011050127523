import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import ReactDOMServer from 'react-dom/server';
import MarkerText from './MarkerText';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StoreIcon from '@mui/icons-material/Store';
import { districts } from './Districs';
import MenuUser from './MenuUser';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Maps = ({ userId, handleEntregaClick, handleRecojoClick, setActiveComponent }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [userMarkers, setUserMarkers] = useState([]);
  const [deliveryPointMarkers, setDeliveryPointMarkers] = useState([]);
  const [driverMarkers, setDriverMarkers] = useState([]);
  const [user, setUser] = useState(null);
  const [deliveryPoints, setDeliveryPoints] = useState([]);
  const [orders, setOrders] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  

  useEffect(() => {
    const fetchUserData = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}`)
        .then(response => {
          setUser(response.data);
          console.log('user ', response.data);
          setDeliveryPoints(response.data.delivery_points || []);
          setOrders(response.data.order_points || []);
          console.log(response.data.delivery_points);
          console.log(response.data.order_points);
        })
        .catch(error => console.error('Error fetching data:', error));
    };
  
    // Call the function immediately and then set an interval
    fetchUserData();
    const intervalId = setInterval(fetchUserData, 5000); // 5000ms = 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
    
  }, [userId]);

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-77.03711992646711, -12.060235303763926], // Default coordinates (Lima)
        zoom: 12
      });
  
      mapRef.current.on('load', () => {
        // initializeUserMarker();
        // initializeDeliveryPointMarkers(); if necessary but doble markers are showing
        fitMapToMarkers();
      });
    } else if (mapRef.current) {
      // initializeUserMarker();
      // initializeDeliveryPointMarkers();
      // fitMapToMarkers();
    }
  }, [user]);

  useEffect(() => {
    const fetchDrivers = async () => {
        try {
            // Extract all driver IDs from deliveryPoints and orders
            const driverIds = [
                ...new Set([
                    ...deliveryPoints.map(dp => dp.driver_id),
                    ...orders.map(order => order.driver_id)
                ])
            ];

            if (driverIds.length > 0) {
      
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/drivers`, {
                    driver_ids: driverIds
                });

                setDrivers(response.data.drivers);
                console.log('Drivers:', response.data.drivers);
            }
        } catch (error) {
            console.error('Error fetching drivers:', error);
        }
    };

    // Run the fetchDrivers function immediately and then every 2 seconds
    fetchDrivers();
    const intervalId = setInterval(fetchDrivers, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
}, [deliveryPoints]);
  
  useEffect(() => {
    if (mapRef.current) {
      initializeDriverMarkers();
    }
  }, [drivers]);

  useEffect(() => {
    if (mapRef.current) {
      initializeDeliveryPointMarkers();
      fitMapToMarkers();
    }
  }, [deliveryPoints]);
  
  useEffect(() => {
    if (mapRef.current && orders.length > 0 && user.addresses.length > 0) {
      initializeUserMarkers();
    }
  }, [orders, user]);

// Function to fit the map to the markers
const fitMapToMarkers = () => {
  if (mapRef.current) {
    const bounds = new mapboxgl.LngLatBounds();

    // Add user location to bounds
    if (user && user.addresses && user.addresses.length > 0) {
      user.addresses.forEach(address => {
        if (address.latitude && address.longitude) {
          bounds.extend([address.longitude, address.latitude]);
        }
      });
    }

    // Add delivery points to bounds
    deliveryPoints.forEach(point => {
      if (point.longitude && point.latitude) {
        bounds.extend([point.longitude, point.latitude]);
      }
    });
    drivers.forEach(driver => {
      if (driver.longitude && driver.latitude) {
        bounds.extend([driver.longitude, driver.latitude]);
      }
    })

    // Fit the map to the bounds, with some padding
    if (!bounds.isEmpty()) {
      mapRef.current.fitBounds(bounds, { padding: 50 });
    }
  }
};


const initializeUserMarkers = () => {
  // Remove existing user markers if present
  if (userMarkers) {
    userMarkers.forEach(marker => marker.remove());
  }

  if (orders.length > 0 && user.addresses.length > 0) {
    orders.forEach(order => {
      // Find the matching address in user.addresses using order.address_id
      const orderAddress = user.addresses.find(address => address.id === order.address_id);
      
      if (orderAddress && orderAddress.latitude && orderAddress.longitude) {
        // Create a DOM element containing the StoreIcon
        const storeIconElement = document.createElement('div');
        storeIconElement.innerHTML = ReactDOMServer.renderToString(
          <StoreIcon style={{ fontSize: '40px', color: 'black' }} />
        );

        // Create the marker using the store icon
        const marker = new mapboxgl.Marker({
          element: storeIconElement
        })
          .setLngLat([orderAddress.longitude, orderAddress.latitude])  // Use the address lat/lon
          .setPopup(new mapboxgl.Popup().setHTML(`<h2 style="color: black;">${user.username}</h3>`))
          .addTo(mapRef.current);
      }
    });
  }
};



const initializeDeliveryPointMarkers = () => {
  // Remove existing markers
  Object.values(deliveryPointMarkers).forEach(marker => marker.remove());
  const newMarkers = {};

  deliveryPoints.forEach(point => {
    let { latitude, longitude } = point;
    let color;

    // Determine the marker color based on the status
    switch (point.status) {
      case 'pending':
        color = 'orange';
        break;

      case 'entregado':
        color = 'green';
        break;
      case 'cancelado':
        color = 'red';
        break;
      case 'encamino':
        color = 'blue';
        break;
      default:
        color = 'gray';
    }

    // Check if latitude and longitude are missing
    if (!latitude || !longitude) {
      // Look for the district data
      const districtData = districts[point.distric];
      if (districtData) {
        // Set latitude and longitude based on the district data
        latitude = districtData.latitude + (Math.random() - 0.5) * 0.01; // Random offset
        longitude = districtData.longitude + (Math.random() - 0.5) * 0.01; // Random offset
      }
    }

    // Only create a marker if we have valid coordinates
    if (latitude && longitude) {
      const marker = new mapboxgl.Marker({ color })
        .setLngLat([longitude, latitude])
        .addTo(mapRef.current);

      marker.getElement().addEventListener('click', () => {
        setSelectedPoint(point);
        mapRef.current.flyTo({
          center: [longitude, latitude],
          zoom: 16,
          offset: [0, -225]  // Adjust the offset
        });
      });

      newMarkers[point.id] = marker;
    }
  });

  setDeliveryPointMarkers(newMarkers);
};

const initializeDriverMarkers = () => {
  // Clear existing markers
  Object.values(driverMarkers).forEach(marker => marker.remove());
  const newMarkers = {};

  drivers.forEach(driver => {
    if (driver.longitude && driver.latitude) {
      // Create a DOM element for the marker
      const logoElement = document.createElement('div');
      logoElement.innerHTML = `<img src="${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png" alt="Logo" style="height: 30px; width: 30px;" />`;

      newMarkers[driver.id] = new mapboxgl.Marker({
        element: logoElement
      })
        .setLngLat([driver.longitude, driver.latitude])
        .setPopup(new mapboxgl.Popup().setHTML(`
          <div>
            <h3 style="color: black; display: inline; font-size: 22px;">${driver.name}</h3>
            <span style="cursor: pointer; display: inline-block; margin-left: 10px;" onclick="window.open('https://wa.me/51${driver.email}', '_blank')">
              ${ReactDOMServer.renderToString(<WhatsAppIcon style={{ fontSize: '24px', color: 'green' }} />)}
            </span>
          </div>
        `))
        .addTo(mapRef.current);
    }
  });

  setDriverMarkers(newMarkers);
};

const handleClosePopup = () => {
    setSelectedPoint(null);
    fitMapToMarkers(); // Reset map zoom to include all markers
  };

 
const handleLogoClick = () => {
  setActiveComponent('Options')
};

  return (
    <div>
      <div
        ref={mapContainerRef}
        style={{ width: "100%", height: "60vh" }}
      ></div>

      <div
        style={{
          position: "absolute",
          top: "15px",
          left: "15px",
          zIndex: 2000, // Ensure it stays on top
        }}
        onClick={handleLogoClick}
      >
        <img
          src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
          alt="Logo"
          style={{ height: "58px", width: "58px" }}
        />
      </div>
      <div
        style={{
          width: "75%",
          position: "absolute",
          top: "80px",
          left: "10px",
          zIndex: 2000, // Ensure it stays on top
        }}
      >
        {selectedPoint && (
          <MarkerText point={selectedPoint} onClose={handleClosePopup} />
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: "160px",
          borderRadius: "50%",
          left: "35%",
          backgroundColor: "white",
          marginBottom: "50px",
        }}
      >
      </div>
      
      {orders.length > 0 &&(
        <IconButton
        style={{
          position: "absolute",
          bottom: "54%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(0.7)",
        }}
        onClick={handleRecojoClick}
      >
        <LocationOnIcon />
      </IconButton>
      )}
      {orders.length > 0 && (
        <IconButton
          style={{
            position: "absolute",
            bottom: "44%",
            right: 30,
            zIndex: 1000,
            backgroundColor: "white",
            border: "2px solid black",
            borderRadius: "80%",
            color: "black",
            marginBottom: "10px",
            transform: "scale(1.7)",
          }}
          onClick={handleEntregaClick}
        >
          <AddIcon />
        </IconButton>
      )}
      <div
        style={{
          height: "40vh",
          padding: "20px",
          backgroundColor: "#f7f7f7",
          overflowY: "auto",
        }}
      >
        {user && (
          <MenuUser
            user={user}
            deliveryPoints={deliveryPoints}
            setActiveComponent={setActiveComponent}
            orders={orders}
            handleRecojoClick={handleRecojoClick}
          />
        )}
      </div>
    </div>
  );
};

export default Maps;
import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Card, CardContent, IconButton, Typography, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const AddRecojo = ({ user_id, onClose, user }) => {
    const [date, setDate] = useState(new Date());
    const [notes, setNotes] = useState('');
    const [selectedAddressId, setSelectedAddressId] = useState('');

    // Log addresses to verify the user prop
    useEffect(() => {
        if (user && user.addresses) {
            console.log('User addresses:', user.addresses);
            if (user.addresses.length > 0) {
                setSelectedAddressId(user.addresses[0].id);  // Default to the first address if available
            }
        }
    }, [user]);

    const handleSave = async () => {
        try {
            const formattedData = {
                user_id,  // user_id passed as a prop
                address_id: selectedAddressId,  // Include selected address ID
                pickup_date: date.toISOString().split('T')[0],  // Format the date to 'YYYY-MM-DD'
                notes
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/api/recojo`, formattedData);
            console.log('Data saved successfully:', formattedData);
            onClose();  // Close the edit card after saving
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    if (!user || !user.addresses) {
        return <Typography variant="h6">Cargando direcciones...</Typography>;
    }

    return (
        <Card sx={{ maxWidth: 300, position: 'absolute', top: "80px", left: "820px", maxHeight: "400px", overflowY: 'auto' }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Guardar
                    </Button>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h6">Agregar Recojo</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {/* Dropdown to select the address */}
                    
                    
                    <TextField
                        label="Notas"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}  // Update notes state
                        fullWidth
                    />
                    <TextField
                        label="Fecha de Recojo"
                        type="date"
                        value={date.toISOString().split('T')[0]}  // Display only the date part
                        onChange={(e) => setDate(new Date(e.target.value))}  // Update date state
                        fullWidth
                    />
                    <TextField
                            label="Seleccionar Dirección"
                            select
                            value={selectedAddressId}
                            onChange={(e) => setSelectedAddressId(e.target.value)}  // Update selected address
                            fullWidth
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: {
                                        maxHeight: 400, // Adjust as necessary for your layout
                                        overflow: 'auto',
                                        position: 'absolute',
                                        left: '50%', // Position it next to the EditionCard
                                        top: '80px',
                                        zIndex: 2500,
                                        paddingLeft: '40px',
                                    },
                                },
                            }}
                        >
                            {user?.addresses?.map((address) => (
                                <MenuItem key={address.id} value={address.id}>
                                    {address.address}
                                </MenuItem>
                            ))}
                        </TextField>
                </Box>
                
            </CardContent>
        </Card>
    );
};

export default AddRecojo;

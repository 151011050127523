import React, { useState } from "react";
import axios from 'axios';
import Input from "./Input";

function Register({ onRegistrationSuccess, setIsUserRegistered }) {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Contraseñas no coinciden");
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, {
        username: formData.username,
        email: formData.email,
        phone_number: formData.phone,
        password: formData.password,
        role: "customer"
      });
      alert(response.data.message);
      onRegistrationSuccess(); 
    } catch (error) {
      console.error('Error response:', error.response);
      if (error.response) {
        alert(error.response.data.message);
      } else {
        alert('Error', error.message);
      }
    }
  };

  return (
    <form
      className="form"
      onSubmit={handleSubmit}
      style={{
        maxWidth: '400px',
        margin: '80px auto',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '10px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
        textAlign: 'center'
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`}
        alt="Logo"
        style={{ height: "58px", width: "58px", marginBottom: '20px' }}
      />
      <h2 style={{ marginBottom: '20px', fontSize: '24px', color: '#333' }}>Register</h2>
      <Input
        name="username"
        type="text"
        placeholder="Usuario"
        value={formData.username}
        onChange={handleChange}
        required
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          border: '1px solid #ddd',
          borderRadius: '5px',
          fontSize: '16px',
          boxSizing: 'border-box',
          color: 'black'
        }}
      />
      <Input
        name="email"
        type="email"
        placeholder="Correo"
        value={formData.email}
        onChange={handleChange}
        required
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          border: '1px solid #ddd',
          borderRadius: '5px',
          fontSize: '16px',
          boxSizing: 'border-box',
          color: 'black'
        }}
      />
      <Input
        name="phone"
        type="phone"
        placeholder="WhatsApp"
        value={formData.phone}
        onChange={handleChange}
        required
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          border: '1px solid #ddd',
          borderRadius: '5px',
          fontSize: '16px',
          boxSizing: 'border-box',
          color: 'black'
        }}
      />
      <Input
        name="password"
        type="password"
        placeholder="Contraseña"
        value={formData.password}
        onChange={handleChange}
        required
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          border: '1px solid #ddd',
          borderRadius: '5px',
          fontSize: '16px',
          boxSizing: 'border-box',
          color: 'black'
        }}
      />
      <Input
        name="confirmPassword"
        type="password"
        placeholder="Confirma tu Contraseña"
        value={formData.confirmPassword}
        onChange={handleChange}
        required
        style={{
          width: '100%',
          padding: '10px',
          margin: '10px 0',
          border: '1px solid #ddd',
          borderRadius: '5px',
          fontSize: '16px',
          boxSizing: 'border-box',
          color: 'black'
        }}
      />
      <button
        type="submit"
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: 'black',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          fontSize: '18px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease'
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = '#333'}
        onMouseOut={(e) => e.target.style.backgroundColor = 'black'}
      >
        Register
      </button>
      <p style={{ marginTop: '15px', color: '#777' }}>
        ¿Ya tienes una cuenta?{' '}
        <a
          href="#"
          onClick={() => setIsUserRegistered(true)}
          style={{
            color: '#007bff',
            textDecoration: 'none',
            fontWeight: 'bold'
          }}
          onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
          onMouseOut={(e) => e.target.style.textDecoration = 'none'}
        >
          Login here
        </a>
      </p>
    </form>
  );
}

export default Register;

import React, { useState } from 'react';
import { Box, TextField, Button, Card, CardContent, IconButton, Typography, MenuItem, Switch, FormControlLabel, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const EditionUserCard = ({ user, onClose }) => {
    const [formData, setFormData] = useState({ ...user });

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSave = async () => {
        try {
            const formattedData = {
                ...formData,
            
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/api/update/user_id`, formattedData);
            console.log('Data saved successfully:', formattedData);
            onClose();  // Close the edit card after saving
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <Card sx={{ maxWidth: 250, position: 'absolute', top: "80px", left: "820px", maxHeight: "380px", overflowY: 'auto', }}>
            <CardContent>
                <Typography variant="h6">Editar Usuario</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email || ''}
                        onChange={handleInputChange}
                        type="email"
                    />
                    <TextField
                        label="username"
                        name="username"
                        value={formData.username || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Telefono"
                        name="phone_number"
                        value={formData.phone_number || ''}
                        onChange={handleInputChange}    
                    />
                    <TextField
                        label="role"
                        name="role"
                        value={formData.role || ''}
                        onChange={handleInputChange}    
                    />
                    <TextField
                        label="Clave"
                        name="password_hash"
                        value={formData.password_hash || ''}
                        onChange={handleInputChange}    
                    />
                    
            

                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
};

export default EditionUserCard;

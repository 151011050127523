import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ButtonBase, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeliveryDriver from './DeliveryDriver';

function DeliveryList({ onBackClick, userId}) {
    const [deliveries, setDeliveries] = useState([]);
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState(null);

    useEffect(() => {
        const fetchDeliveryPoints = async () => {
            const token = localStorage.getItem('token');
            try { 
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/driver/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDeliveries(response.data);
                console.log('Fetching delivery points:', response.data);
            } catch (error) {
                console.error('Error fetching delivery points:', error);
            }
        }; 
        if (userId) {
            fetchDeliveryPoints();
        }
    }, [userId]);

    useEffect(() => {
        if (deliveries.length > 0) {
            handleDeliveriesRoutes();
        }
    }, [deliveries]);

    const handleDeliveriesRoutes = async () => {
        if (deliveries.length === 0) return;

        const sortedDeliveries = deliveries
            .filter(delivery => delivery.status === 'pending')
            .sort((a, b) => a.sequence - b.sequence);
    
        // Check if there are any pending orders
        if (sortedDeliveries.length === 0) {
            console.log('No pending Deliveries to process.');
            return;
        }
    
        // Get the driver's current location
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;
                const startPoint = [latitude, longitude]; // Use the driver's current location as the start point
                
                // Sort order points by sequence and filter by pending status
                const sortedDeliveries = deliveries.filter(delivery => delivery.status === 'pending').sort((a, b) => a.sequence - b.sequence);
                const endPoint = [sortedDeliveries[sortedDeliveries.length - 1].latitude, sortedDeliveries[sortedDeliveries.length - 1].longitude];
                const stops = sortedDeliveries.map(delivery => [delivery.latitude, delivery.longitude]);
    
                try {
                    // Create the route using the driver's current location as the start point
                    const route = await createRoute(startPoint, endPoint, stops);
                    const arrivalTimes = route.legs.map(leg => leg.summary.arrivalTime);
    
                    const updatedDeliveries = sortedDeliveries.map((delivery, index) => ({
                        id: delivery.id,
                        arrival_time: arrivalTimes[index],
                        timezone: 'America/Lima'  // Explicitly set the timezone to Peru
                    }));
    
                    // Call backend to update arrival times
                    const token = localStorage.getItem("token");
                    await axios.post(`${process.env.REACT_APP_API_URL}/api/update_arrival_times_deliveries`, { deliveries: updatedDeliveries }, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
    
                    console.log('Arrival times updated successfully');
                } catch (error) {
                    console.error('Error updating arrival times:', error);
                }
            },
            (error) => {
                console.error('Error getting current location:', error);
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );
    };

    const createRoute = async (startPoint, endPoint, stops) => {
        const coordinates = [startPoint, ...stops, endPoint].map(coord => coord.join(',')).join(':');
        const subscriptionKey = 'Achm_e9abrKvupF8HjuPnaMAEiTBavpXTPj30Q8CO4U';

        const url = 'https://atlas.microsoft.com/route/directions/json';
        const params = {
            'api-version': '1.0',
            'query': coordinates,
            'subscription-key': subscriptionKey,
            'routeType': 'fastest',
            'optimize': 'time'
        };

        try {
            const response = await axios.get(url, { params });
            return response.data.routes[0];
        } catch (error) {
            console.error('Error creating route:', error);
            throw error;
        }
    };

    if (selectedDeliveryPoint) {
        return <DeliveryDriver delivery={selectedDeliveryPoint} onBack={() => setSelectedDeliveryPoint(null)} onBackClick={onBackClick} />; // Render OrderDriver if an order is selected
    }


    return (
        <div style={{ maxHeight: '700px', overflowY: 'auto' }}>
            <ButtonBase onClick={() => { onBackClick() }} style={{ padding: '20px', color: 'black' }}>
                <ArrowBackIcon />
            </ButtonBase>
            <h1 style={{ color: 'black', fontSize: '2em', margin: '20px' }}>Entregas</h1>
            <List>
                {deliveries.sort((a, b) => a.sequence - b.sequence).map((delivery, index) => (
                    <ButtonBase
                        onClick={() => !delivery.is_submitted && setSelectedDeliveryPoint(delivery)}
                        key={index}
                        disabled={delivery.is_submitted} // Disable if order is already submitted
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <SupportAgentIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span style={{ color: 'black' }}>{delivery.id} Punto {delivery.sequence}</span>}
                                secondary={`${delivery.recipient_name.substring(0, 15)}, Estado: ${delivery.status}`}
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </ButtonBase>
                ))}
            </List>
        </div>
    );
}

export default DeliveryList;
import React, { useState } from 'react';
import { Box, TextField, Button, Card, CardContent, IconButton, Typography, MenuItem, Switch, FormControlLabel, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { format } from 'date-fns';

const EditionOrderCard = ({ order, onClose }) => {
    const [formData, setFormData] = useState({ ...order });

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');

            // Format the delivery_date to match the expected format
            const formattedData = {
                ...formData,
                recojo_date: formData.recojo_date
                    ? format(new Date(formData.recojo_date), 'yyyy-MM-dd')
                    : formData.recojo,
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/api/update/order`, formattedData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Data saved successfully:', formattedData);
            onClose();  // Close the edit card after saving
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <Card sx={{ maxWidth: 250, position: 'absolute', top: "80px", left: "620px", maxHeight: "380px", overflowY: 'auto', }}>
            <CardContent>
                <Typography variant="h6">Editar Recojo</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="User ID"
                        name="customer_id"
                        value={formData.customer_id || ''}
                        onChange={handleInputChange}
                        type="number"
                    />
                    <TextField
                        label="Notes"
                        name="order_notes"
                        value={formData.order_notes || ''}
                        onChange={handleInputChange}
                    />
                    
                    
                    <TextField
                        label=""
                        name="recojo_date"
                        value={formData.recojo_date || ''}
                        onChange={handleInputChange}
                        type="datetime-local"
                    />

                    <TextField
                        label="Direccion"
                        name="address"
                        value={formData.address || ''}
                        onChange={handleInputChange}
                    />

                    <TextField
                        label="Latitude"
                        name="latitude"
                        value={formData.latitude || ''}
                        onChange={handleInputChange}
                    />

                    <TextField
                        label="Longitude"
                        name="longitude"
                        value={formData.longitude || ''}
                        onChange={handleInputChange}
                    />

                    <TextField
                        label="Conductor ID"
                        name="driver_id"
                        value={formData.driver_id || ''}
                        onChange={handleInputChange}
                        type="number"
                    />
                    <TextField
                        label="Sequencia"
                        name="sequence"
                        value={formData.sequence || ''}
                        onChange={handleInputChange}
                        type="number"
                    />
                    <TextField
                        label="Estado"
                        name="status"
                        value={formData.status || ''}
                        onChange={handleInputChange}
                        select
                        style={{ width: '100%' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: {
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    position: 'absolute',
                                    right: '50%',
                                    top: '80px',
                                    zIndex: 2500,
                                    paddingLeft: '40px',
                                },
                            },
                        }}
                    >
                        <MenuItem value="pending">Pendiente</MenuItem>
                        <MenuItem value="encamino">En camino</MenuItem>
                        <MenuItem value="recogido">Recogido</MenuItem>
                        <MenuItem value="cancelado">Cancelado</MenuItem>
                    </TextField>
                    
                   
                    <TextField
                        label="Notas Conductor"
                        name="driver_notes"
                        value={formData.driver_notes || ''}
                        onChange={handleInputChange}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.is_submitted || false}
                                onChange={handleInputChange}
                                name="is_submitted"
                            />
                        }
                        label="Is Submitted"
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Guardar
                    </Button>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
};

export default EditionOrderCard;

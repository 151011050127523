import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Box, TextField, Typography, List, ListItem, ListItemText, IconButton, Menu, MenuItem } from "@mui/material";
import Carousel from 'react-material-ui-carousel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const ResumenAdmin = ({ onBackClick }) => {
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedDeliveries, setSelectedDeliveries] = useState([]);
    const [images, setImages] = useState([]);
    const [totalDay, setTotalDay] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentOrderId, setCurrentOrderId] = useState(null);
    

    useEffect(() => {
        const fetchResumenData = async () => {
            if (!selectedDate) return;
    
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resumen_data/admin`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    params: { date: selectedDate }
                });
                console.log("Fetched Resumen Data:", response.data);
                setOrders(response.data.recojos);
                setDeliveryPoints(response.data.entregas);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };
    
        fetchResumenData();
    }, [selectedDate, currentOrderId]);
    
    useEffect(() => {
        // Calculate the total income for the day
        let total = 0;
        orders.forEach(order => {
            const associatedDeliveries = deliveryPoints.filter(dp => dp.user_id === order.customer_id && dp.status === 'entregado');
            const numDeliveries = associatedDeliveries.length;
            if (numDeliveries === 1) {
                total += 15; // One delivery point costs 15 soles
            } else if (numDeliveries > 1) {
                total += numDeliveries * 9; // More than one delivery point costs 9 soles each
            }
        });
        setTotalDay(total);
    }, [orders, deliveryPoints]);

    const handleOrderClick = (order) => {
        setSelectedOrder(order);
        
        // Filter delivery points associated with the selected order
        const relevantDeliveries = deliveryPoints.filter(dp => dp.user_id === order.customer_id);
        setSelectedDeliveries(relevantDeliveries);
        setImages([]);  // Clear images when selecting a new order
    
        // Calculate total for the selected order based on the status 'entregado'
        const deliveredPoints = relevantDeliveries.filter(dp => dp.status === 'entregado');
        const numDeliveries = deliveredPoints.length;
        let total = 0;
        let totalDeposit = 0;
    
        if (numDeliveries === 1) {
            total = 15; // One delivery point with status 'entregado' costs 15 soles
        } else if (numDeliveries > 1) {
            total = numDeliveries * 9; // More than one delivery point with status 'entregado' costs 9 soles each
        }
        // Calculate the totalDeposit based on the 'charged' field from the relevant deliveries
        totalDeposit = deliveredPoints.reduce((acc, dp) => acc + dp.charged, 0);    
        setTotalOrder(total); // Set the totalOrder state
        setTotalDeposit(totalDeposit); // Set the totalDeposit state
    };

    const deliveryCancel = deliveryPoints.filter(deliveryPoint => deliveryPoint.status === 'cancelado');

    const handleDeliveryPointClick = (deliveryPoint) => {
        setImages(deliveryPoint.image_urls || []);
    };

    const handleStatusChangeClick = (event, orderId) => {
        setAnchorEl(event.currentTarget);
        setCurrentOrderId(orderId);
    };

    const handleStatusChange = async (status) => {
        if (!currentOrderId) return;

        const token = localStorage.getItem("token");

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/status_change`, {
                orderId: currentOrderId,
                status: status
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            setOrders(prevOrders => prevOrders.map(order =>
                order.id === currentOrderId ? { ...order, status: status } : order
            ));
        } catch (error) {
            console.error("Error updating status:", error);
        } finally {
            setAnchorEl(null);
            setCurrentOrderId(null);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentOrderId(null);
    };
    
    return (
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", padding: 2 }}>
        
        <Box sx={{ marginBottom: 2 }}>
        <ArrowBackIcon onClick={onBackClick} style={{ color: 'black' }}/>
          <TextField
            label="Pick Date"
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ marginTop: 2 }}
          />
        </Box>

        <Box sx={{ display: "flex", flexGrow: 1 }}>
    <Box sx={{ width: "30%", overflowY: "auto", padding: 2, maxHeight: '700px' }}>
        <Typography variant="h6" color="text.primary">Resumen del Día</Typography>
        <Typography variant="body1" color="text.primary">Recojos: {orders.length} Entregas:{deliveryPoints.length} Cacelado:{deliveryCancel.length}</Typography>
        <Typography variant="body1" color="text.primary">Total de Ingreso: {totalDay}</Typography>
        <Typography variant="h6" sx={{ marginTop: 2 }} color="text.primary">Lista de Recojos</Typography>
        <List>
        {orders.map((order) => (
                <ListItem key={order.id} onClick={() => handleOrderClick(order)}>
                    <ListItemText
                        primary={`Recojo ${order.id}: ${order.customer_name} - ${order.status}`}
                        primaryTypographyProps={{ color: "text.primary" }}
                    />
                    <IconButton
                        color="secondary"
                        onClick={(event) => handleStatusChangeClick(event, order.id)}
                    >
                        <AttachMoneyIcon />
                    </IconButton>
                </ListItem>
            ))}
        </List>
    </Box>
    <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleStatusChange('pagado')}>Pagado</MenuItem>
                <MenuItem onClick={() => handleStatusChange('cancelado')}>Cancelado</MenuItem>
            </Menu>

    <Box sx={{ width: "30%", overflowY: "auto", padding: 2, maxHeight: '700px' }}>
        <Typography variant="h6" color="text.primary">Recojo Seleccionado</Typography>
        {selectedOrder ? (
            <>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={` ${selectedOrder.customer_name} - ${selectedOrder.status} - ${totalOrder} - $${totalDeposit} - ${totalDeposit - totalOrder}`}
                            primaryTypographyProps={{ color: "text.primary" }}
                        />
                    </ListItem>
                </List>
                <Typography variant="h6" color="text.primary">Entregas Asociadas</Typography>
                <List>
                    {selectedDeliveries.map((deliveryPoint) => (
                        <ListItem
                            button
                            key={deliveryPoint.id}
                            onClick={() => handleDeliveryPointClick(deliveryPoint)}
                        >
                            <ListItemText
                                primary={` Id: ${deliveryPoint.id} - ${deliveryPoint.recipient_name} - ${deliveryPoint.status} - ${deliveryPoint.charge} - $${deliveryPoint.charged} - ${deliveryPoint.transfer} - ${deliveryPoint.notes_driver }`}
                                primaryTypographyProps={{ color: "text.primary" }}
                            />
                        </ListItem>
                    ))}
                </List>
            </>
        ) : (
            <Typography variant="body2" color="text.primary">
                Seleccione un recojo para ver los detalles.
            </Typography>
        )}
    </Box>


          <Box sx={{ width: "35%", overflowY: "auto", padding: 2 }}>
            <Typography variant="h6" color="text.primary">Imágenes</Typography>
            {images.length > 0 ? (
              <Carousel>
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Delivery Image ${index + 1}`}
                    style={{ width: "100%", height: "350px", objectFit: "cover" }}
                  />
                ))}
              </Carousel>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No hay imágenes disponibles.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
};

export default ResumenAdmin;
